import React, { useEffect, useState } from "react";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { useLocation } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { FiLoader } from "react-icons/fi";
import { db, sendEmail } from "../utils/firebase";

const EstimateDetails = () => {
  const location = useLocation();
  const [estimate, setEstimate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // New state variables
  const [emailInput, setEmailInput] = useState("");
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [isConfirming, setIsConfirming] = useState(false); // New state variable
  // New state variable for hovered row index
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  // Extract storeId and estimateId from query parameters
  const searchParams = new URLSearchParams(location.search);
  const storeId = searchParams.get("storeId");
  const estimateId = searchParams.get("estimateId");

  // Calculations
  const calculateValues = (estimateData) => {
    if (!estimateData || !estimateData.products) {
      return {
        subtotal: 0,
        discountAmount: 0,
        amountAfterDiscount: 0,
        taxAmount: 0,
        totalPrice: 0,
        balanceAmount: 0,
      };
    }

    const subtotal = estimateData.products.reduce(
      (acc, product) => acc + product.item_total,
      0
    );

    let discountAmount = 0;
    if (estimateData.discount_type === "percentage") {
      discountAmount = subtotal * (estimateData.discount / 100);
    } else if (estimateData.discount_type === "fixed") {
      discountAmount = estimateData.discount;
    }

    const amountAfterDiscount = subtotal - discountAmount;

    let taxAmount = 0;
    if (estimateData.tax_type === "percentage") {
      taxAmount = amountAfterDiscount * (estimateData.tax / 100);
    } else if (estimateData.tax_type === "fixed") {
      taxAmount = estimateData.tax;
    }

    const totalPrice = amountAfterDiscount + taxAmount;
    const balanceAmount = totalPrice - estimateData.payment_received;

    return {
      subtotal,
      discountAmount,
      amountAfterDiscount,
      taxAmount,
      totalPrice,
      balanceAmount,
    };
  };

  useEffect(() => {
    const fetchEstimate = async () => {
      if (!storeId || !estimateId) {
        setError(true);
        setLoading(false);
        return;
      }

      try {
        const estimateRef = doc(db, "Stores", storeId, "Estimates", estimateId);
        const estimateSnap = await getDoc(estimateRef);

        if (estimateSnap.exists()) {
            console.log("Estimate document data:", estimateSnap.data());
          setEstimate(estimateSnap.data());
        } else {
          setError(true);
        }
      } catch (err) {
        console.error("Error fetching estimate:", err);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchEstimate();
  }, [storeId, estimateId]);


  const handleConfirmClick = () => {
    setIsEmailModalOpen(true);
    setConfirmationMessage("");
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setIsConfirming(true); // Start loading

    try {
      // Fetch the latest estimate data
      const estimateRef = doc(db, "Stores", storeId, "Estimates", estimateId);
      const estimateSnap = await getDoc(estimateRef);

      if (estimateSnap.exists()) {
        const latestEstimate = estimateSnap.data();

        // Compare emails
        const estimateEmail = latestEstimate.estimateData.customer.customer_email;

        if (
          emailInput.trim().toLowerCase() === estimateEmail.trim().toLowerCase()
        ) {
          // Update the document with isConfirmed: true
          await updateDoc(estimateRef, { isConfirmed: true });

          // Send confirmation email
          await sendEmail({
            to: estimateEmail,
            subject: `Estimate #${latestEstimate.estimateNo} Confirmation`,
            html: `
              <div style="font-family: Arial, sans-serif; color: #333;">
                <h1 style="color: #4CAF50;">Estimate Confirmed</h1>
                <p>Dear ${latestEstimate.estimateData.customer.customer_name},</p>
                <p>Thank you for confirming your estimate. We have received your confirmation and will proceed accordingly.</p>
                <p>If you have any questions, feel free to contact us.</p>
                <br/>
                <p>Best regards,</p>
                <p>Your Company Name</p>
              </div>
            `,
          });

          // Update state
          setEstimate((prev) => ({ ...prev, isConfirmed: true }));
          setConfirmationMessage("Estimate successfully confirmed!");
          setIsEmailModalOpen(false);
          setEmailInput("");
        } else {
          setConfirmationMessage(
            "The email you entered does not match our records."
          );
        }
      } else {
        setError(true);
      }
    } catch (err) {
      console.error("Error during confirmation:", err);
      setConfirmationMessage("An error occurred. Please try again later.");
    } finally {
      setIsConfirming(false); // End loading
    }
  };

  const downloadPDF = async () => {
    const pdfDoc = await PDFDocument.create();
  
    // Embed fonts
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
  
    // Page dimensions and styling variables
    const pageWidth = 595.28;
    const pageHeight = 841.89; // A4 size in points
    const margin = 50;
    const fontSize = 12;
    const lineHeight = fontSize * 1.2;
    const bottomMargin = 50;
  
    // Initialize variables
    let y = pageHeight - margin;
    let page = pdfDoc.addPage([pageWidth, pageHeight]);
  
    // Destructure estimate data
    const {
      customer,
      products,
      discount,
      discount_type,
      tax,
      tax_type,
      payment_received,
      notes,
    } = estimate.estimateData;
  
    const estimateNo = estimate.estimateNo;
    const validUntil = estimate.validUntil.seconds * 1000;
  
    // Function to add a new page
    const addNewPage = () => {
      page = pdfDoc.addPage([pageWidth, pageHeight]);
      y = pageHeight - margin;
    };
  
    // Function to calculate remaining space
    const calculateRemainingSpace = () => y - bottomMargin;
  
    // Function to calculate the height required for summary and notes
    const calculateSummaryAndNotesHeight = () => {
      let totalLines = 0;
  
      // Summary lines
      totalLines += 8; // Number of lines in summary including spacings
  
      // Notes lines
      if (notes.customer_note || notes.terms_and_conditions) {
        totalLines += 2; // For 'Notes:' header and spacing
        if (notes.customer_note) {
          const customerNoteLines = wrapText(
            notes.customer_note,
            pageWidth - margin * 2,
            fontSize,
            font
          );
          totalLines += customerNoteLines.length;
        }
        if (notes.terms_and_conditions) {
          totalLines += 2; // For 'Terms & Conditions:' header and spacing
          const termsLines = wrapText(
            notes.terms_and_conditions,
            pageWidth - margin * 2,
            fontSize,
            font
          );
          totalLines += termsLines.length;
        }
      }
  
      return totalLines * lineHeight;
    };
  
    // Function to check if there's enough space, if not, add new page
    const ensureSpaceFor = (requiredHeight) => {
      if (calculateRemainingSpace() < requiredHeight) {
        addNewPage();
      }
    };
  
    // Add company logo or name
    page.drawText("Your Company Name", {
      x: margin,
      y,
      size: 24,
      font: boldFont,
      color: rgb(0, 0.53, 0.71),
    });
    y -= lineHeight * 2;
  
    // Header
    page.drawText(`Estimate No: ${estimateNo}`, {
      x: margin,
      y,
      size: fontSize,
      font: boldFont,
    });
    y -= lineHeight;
    page.drawText(`Date: ${new Date().toLocaleDateString()}`, {
      x: margin,
      y,
      size: fontSize,
      font,
    });
    y -= lineHeight;
    page.drawText(`Valid Until: ${new Date(validUntil).toLocaleDateString()}`, {
      x: margin,
      y,
      size: fontSize,
      font,
    });
    y -= lineHeight * 2;
  
    // Customer Information
    page.drawText("Bill To:", { x: margin, y, size: fontSize, font: boldFont });
    y -= lineHeight;
    page.drawText(`${customer.customer_name}`, {
      x: margin,
      y,
      size: fontSize,
      font,
    });
    y -= lineHeight * 2;
  
    // Table Headers
    const tableLeftX = margin;
    const tableRightX = pageWidth - margin;
    const columnWidths = [80, 200, 50, 70, 70];
  
    const headers = ["Item", "Description", "Qty", "Unit Price", "Total"];
    let currentX = tableLeftX;
    headers.forEach((header, index) => {
      page.drawText(header, {
        x: currentX + 5,
        y,
        size: fontSize,
        font: boldFont,
      });
      currentX += columnWidths[index];
    });
    y -= lineHeight;
  
    // Draw header line
    page.drawLine({
      start: { x: tableLeftX, y: y + 5 },
      end: { x: tableRightX, y: y + 5 },
      thickness: 1,
      color: rgb(0, 0, 0),
    });
  
    y -= lineHeight / 2;
  
    // Prepare for content rendering
    const summaryAndNotesHeight = calculateSummaryAndNotesHeight();
  
    // Table Content
    for (let product of products) {
      currentX = tableLeftX;
      const cellPadding = 5;
      const rowXPositions = [];
      for (let i = 0; i < columnWidths.length; i++) {
        rowXPositions.push(currentX);
        currentX += columnWidths[i];
      }
  
      // Wrap description text
      const description = `Room: ${product.user_input.room_name}\nDimensions: ${product.user_input.width} x ${product.user_input.height} ${product.user_input.measurement_type}`;
      const descriptionLines = wrapText(
        description,
        columnWidths[1] - cellPadding * 2,
        fontSize,
        font
      );
  
      const itemLines = wrapText(
        product.product.name,
        columnWidths[0] - cellPadding * 2,
        fontSize,
        font
      );
      const qtyLines = [product.user_input.quantity.toString()];
      const unitPriceLines = [`$${parseFloat(product.product.price).toFixed(2)}`];
      const totalLines = [`$${product.item_total.toFixed(2)}`];
  
      // Determine the maximum number of lines in this row
      const maxLines = Math.max(
        descriptionLines.length,
        itemLines.length,
        qtyLines.length,
        unitPriceLines.length,
        totalLines.length
      );
      const rowHeight = maxLines * lineHeight + 10; // Adding extra space between rows
  
      // Ensure there is enough space for the row and summary/notes
      ensureSpaceFor(rowHeight + summaryAndNotesHeight);
  
      // Center the text vertically in the row
      let cellY = y - (rowHeight - lineHeight) / 2;
  
      // Draw Item
      drawCellText(
        page,
        itemLines,
        rowXPositions[0] + cellPadding,
        cellY,
        columnWidths[0] - cellPadding * 2,
        fontSize,
        font
      );
  
      // Draw Description
      drawCellText(
        page,
        descriptionLines,
        rowXPositions[1] + cellPadding,
        cellY,
        columnWidths[1] - cellPadding * 2,
        fontSize,
        font
      );
  
      // Draw Quantity
      drawCellText(
        page,
        qtyLines,
        rowXPositions[2] + cellPadding,
        cellY,
        columnWidths[2] - cellPadding * 2,
        fontSize,
        font
      );
  
      // Draw Unit Price
      drawCellText(
        page,
        unitPriceLines,
        rowXPositions[3] + cellPadding,
        cellY,
        columnWidths[3] - cellPadding * 2,
        fontSize,
        font
      );
  
      // Draw Total
      drawCellText(
        page,
        totalLines,
        rowXPositions[4] + cellPadding,
        cellY,
        columnWidths[4] - cellPadding * 2,
        fontSize,
        font
      );
  
      // Draw row bottom line
      y -= rowHeight;
      page.drawLine({
        start: { x: tableLeftX, y: y + 5 },
        end: { x: tableRightX, y: y + 5 },
        thickness: 0.5,
        color: rgb(0.8, 0.8, 0.8),
      });
    }
  
    y -= lineHeight;
  
    // Ensure there is enough space for summary and notes
    ensureSpaceFor(summaryAndNotesHeight);
  
    // Summary
    const summaryLabelX = tableRightX - 200;
    const summaryAmountX = tableRightX - 50;
  
    page.drawText("Subtotal:", { x: summaryLabelX, y, size: fontSize, font });
    page.drawText(`$${subtotal.toFixed(2)}`, {
      x: summaryAmountX,
      y,
      size: fontSize,
      font,
    });
  
    y -= lineHeight;
    page.drawText(
      `Discount (${discount}${discount_type === "percentage" ? "%" : ""}):`,
      { x: summaryLabelX, y, size: fontSize, font }
    );
    page.drawText(`-$${discountAmount.toFixed(2)}`, {
      x: summaryAmountX,
      y,
      size: fontSize,
      font,
    });
  
    y -= lineHeight;
    page.drawText("Amount after Discount:", {
      x: summaryLabelX,
      y,
      size: fontSize,
      font,
    });
    page.drawText(`$${amountAfterDiscount.toFixed(2)}`, {
      x: summaryAmountX,
      y,
      size: fontSize,
      font,
    });
  
    y -= lineHeight;
    page.drawText(
      `Tax (${tax}${tax_type === "percentage" ? "%" : ""}):`,
      { x: summaryLabelX, y, size: fontSize, font }
    );
    page.drawText(`$${taxAmount.toFixed(2)}`, {
      x: summaryAmountX,
      y,
      size: fontSize,
      font,
    });
  
    y -= lineHeight;
    page.drawText("Total Price:", {
      x: summaryLabelX,
      y,
      size: fontSize + 2,
      font: boldFont,
    });
    page.drawText(`$${totalPrice.toFixed(2)}`, {
      x: summaryAmountX,
      y,
      size: fontSize + 2,
      font: boldFont,
    });
  
    y -= lineHeight * 2;
    page.drawText("Payment Received:", {
      x: summaryLabelX,
      y,
      size: fontSize,
      font,
    });
    page.drawText(`-$${payment_received.toFixed(2)}`, {
      x: summaryAmountX,
      y,
      size: fontSize,
      font,
    });
  
    y -= lineHeight;
    page.drawText("Balance Amount:", {
      x: summaryLabelX,
      y,
      size: fontSize + 2,
      font: boldFont,
    });
    page.drawText(`$${balanceAmount.toFixed(2)}`, {
      x: summaryAmountX,
      y,
      size: fontSize + 2,
      font: boldFont,
      color: rgb(1, 0, 0),
    });
  
    y -= lineHeight * 2;
  
    // Notes
    if (notes.customer_note || notes.terms_and_conditions) {
      // Ensure space for notes
      ensureSpaceFor((notes.customer_note ? 2 : 0) + (notes.terms_and_conditions ? 4 : 0) * lineHeight);
  
      if (notes.customer_note) {
        page.drawText("Customer Note:", {
          x: margin,
          y,
          size: fontSize + 2,
          font: boldFont,
        });
        y -= lineHeight;
  
        const customerNoteLines = wrapText(
          notes.customer_note,
          pageWidth - margin * 2,
          fontSize,
          font
        );
        customerNoteLines.forEach((line) => {
          page.drawText(line, { x: margin, y, size: fontSize, font });
          y -= lineHeight;
        });
        y -= lineHeight / 2;
      }
  
      if (notes.terms_and_conditions) {
        page.drawText("Terms & Conditions:", {
          x: margin,
          y,
          size: fontSize + 2,
          font: boldFont,
        });
        y -= lineHeight;
  
        const termsLines = wrapText(
          notes.terms_and_conditions,
          pageWidth - margin * 2,
          fontSize,
          font
        );
        termsLines.forEach((line) => {
          page.drawText(line, { x: margin, y, size: fontSize, font });
          y -= lineHeight;
        });
        y -= lineHeight / 2;
      }
    }
  

    // Save and download the PDF
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `Estimate_${estimateNo}.pdf`;
    link.click();
  };

// Helper function to wrap text
const wrapText = (text, maxWidth, fontSize, font) => {
    const lines = [];
    const paragraphs = text.split('\n'); // Split text into paragraphs
  
    paragraphs.forEach((paragraph) => {
      if (paragraph.trim() === '') {
        // Skip empty paragraphs to avoid extra spacing
        return;
      }
  
      const words = paragraph.split(' ');
      let currentLine = '';
  
      words.forEach((word) => {
        const testLine = currentLine + word + ' ';
        const textWidth = font.widthOfTextAtSize(testLine.trim(), fontSize);
        if (textWidth > maxWidth && currentLine !== '') {
          lines.push(currentLine.trim());
          currentLine = word + ' ';
        } else {
          currentLine = testLine;
        }
      });
  
      if (currentLine.trim() !== '') {
        lines.push(currentLine.trim());
      }
    });
  
    return lines;
  };

  // Helper function to draw text within a cell, centered vertically
  const drawCellText = (page, lines, x, y, width, fontSize, font) => {
    const totalTextHeight = lines.length * fontSize;
    const startY = y + (fontSize - totalTextHeight) / 2;
    lines.forEach((line, idx) => {
      page.drawText(line, { x, y: y - idx * fontSize, size: fontSize, font });
    });
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <FiLoader className="animate-spin text-4xl text-blue-500" />
        <p className="mt-4 text-xl text-gray-600">Loading estimate...</p>
      </div>
    );
  }

  if (error || !estimate || !estimate.estimateData) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 px-4">
        <h1 className="text-3xl font-bold text-gray-800">Estimate Not Found</h1>
        <p className="mt-2 text-lg text-gray-600 text-center">
          We couldn't find the estimate you're looking for. It might have been
          moved or deleted.
        </p>
        <a
          href="/"
          className="mt-6 bg-blue-500 text-white px-6 py-3 rounded-md hover:bg-blue-600 transition"
        >
          Go Back Home
        </a>
      </div>
    );
  }

  // Calculate values
  const {
    subtotal,
    discountAmount,
    amountAfterDiscount,
    taxAmount,
    totalPrice,
    balanceAmount,
  } = calculateValues(estimate.estimateData);

  return (
    <div className="p-8 bg-white shadow-lg rounded-lg max-w-4xl mx-auto my-10">
      {/* Header */}
      <div className="mb-8 text-center">
        <h1 className="text-4xl font-extrabold text-gray-800">
          Estimate Details
        </h1>
        <p className="text-gray-500 mt-2">Estimate No: {estimate.estimateNo}</p>
        <p className="text-gray-500">
          Valid Until:{" "}
          {new Date(estimate.validUntil.seconds * 1000).toLocaleDateString()}
        </p>
      </div>

      {/* Confirmation Status */}
      {estimate.isConfirmed && (
        <div className="mb-8 text-center">
          <span className="inline-block bg-green-100 text-green-800 px-4 py-2 rounded-full font-semibold">
            Confirmed by {estimate.estimateData.customer.customer_name}
          </span>
        </div>
      )}

      {/* Customer Information */}
      <div className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-800">
          Customer Information
        </h2>
        <div className="text-gray-700 mt-4 space-y-2">
          <p>
            <span className="font-medium">Name:</span>{" "}
            {estimate.estimateData.customer.customer_name}
          </p>
          {estimate.estimateData.customer.customer_email && (
            <p>
              <span className="font-medium">Email:</span>{" "}
              {estimate.estimateData.customer.customer_email}
            </p>
          )}
          {estimate.estimateData.customer.customer_phone && (
            <p>
              <span className="font-medium">Phone:</span>{" "}
              {estimate.estimateData.customer.customer_phone}
            </p>
          )}
        </div>
      </div>

      {/* Items Table */}
      <div className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-800">Items</h2>
        <div className="overflow-x-auto mt-6">
          <table className="min-w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-200">
                <th className="border border-gray-300 px-4 py-3 text-left text-gray-600">
                  Item
                </th>
                <th className="border border-gray-300 px-4 py-3 text-left text-gray-600">
                  Description
                </th>
                <th className="border border-gray-300 px-4 py-3 text-center text-gray-600">
                  Qty
                </th>
                <th className="border border-gray-300 px-4 py-3 text-right text-gray-600">
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              {estimate.estimateData.products.map((product, index) => (
                <React.Fragment key={index}>
                  <tr
                    className="hover:bg-gray-50"
                    onMouseEnter={() => setHoveredRowIndex(index)}
                    onMouseLeave={() => setHoveredRowIndex(null)}
                  >
                    <td className="border border-gray-300 px-4 py-3 sm:text-base text-sm">
                      {product.product.name}
                    </td>
                    <td className="border border-gray-300 px-4 py-3 whitespace-pre-line sm:text-base text-sm">
                      {`${product.user_input.room_name}\n ${product.user_input.width} x ${product.user_input.height} ${product.user_input.measurement_type}`}
                    </td>
                    <td className="border border-gray-300 px-4 py-3 text-center">
                      {product.user_input.quantity}
                    </td>
                    <td className="border border-gray-300 px-4 py-3 text-right">
                      ${product.item_total.toFixed(2)}
                    </td>
                  </tr>
                  {hoveredRowIndex === index &&
                    product.selected_variations &&
                    product.selected_variations.length > 0 && (
                      <tr>
                        <td
                          colSpan="4"
                          className="bg-gray-100 border border-gray-300"
                        >
                          <div className="p-4">
                            <h3 className="font-semibold text-gray-800 mb-2 sm:text-base text-sm">
                              Selected Customizations
                            </h3>
                            {product.selected_variations.map(
                              (variation, vIndex) => (
                                <div key={vIndex} className="mb-4">
                                  <p className="font-medium text-gray-700 sm:text-base text-sm">
                                    Customization:{" "}
                                    {variation.variation.variation_name} - $
                                    {parseFloat(
                                      variation.variation.variation_price
                                    ).toFixed(2)}
                                  </p>
                                  {variation.variation_details &&
                                    Object.keys(
                                      variation.variation_details
                                    ).map((detailKey, dIndex) => {
                                      const detail =
                                        variation.variation_details[detailKey];
                                      return (
                                        <div key={dIndex} className="ml-4">
                                          <p className="text-gray-600 sm:text-base text-sm">
                                            {detailKey}: {detail.variation_detail_name}{" "}
                                            - $
                                            {parseFloat(
                                              detail.variation_detail_price || 0
                                            ).toFixed(2)}
                                          </p>
                                        </div>
                                      );
                                    })}
                                </div>
                              )
                            )}
                          </div>
                        </td>
                      </tr>
                    )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Summary */}
      <div className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-800">Summary</h2>
        <div className="mt-6 text-gray-700">
          <div className="flex justify-between py-2">
            <span>Subtotal:</span>
            <span>${subtotal.toFixed(2)}</span>
          </div>
          <div className="flex justify-between py-2">
            <span>
              Discount ({estimate.estimateData.discount}
              {estimate.estimateData.discount_type === "percentage" ? "%" : ""}
              ):
            </span>
            <span>-${discountAmount.toFixed(2)}</span>
          </div>
          <div className="flex justify-between py-2">
            <span>Amount after Discount:</span>
            <span>${amountAfterDiscount.toFixed(2)}</span>
          </div>
          <div className="flex justify-between py-2">
            <span>
              Tax ({estimate.estimateData.tax}
              {estimate.estimateData.tax_type === "percentage" ? "%" : ""}):
            </span>
            <span>+${taxAmount.toFixed(2)}</span>
          </div>
          <div className="flex justify-between py-4 border-t border-gray-300 mt-4">
            <span className="font-bold text-xl">Total Price:</span>
            <span className="font-bold text-xl">${totalPrice.toFixed(2)}</span>
          </div>
          <div className="flex justify-between py-2">
            <span>Payment Received:</span>
            <span>-${estimate.estimateData.payment_received.toFixed(2)}</span>
          </div>
          <div className="flex justify-between py-4 border-t border-gray-300 mt-4">
            <span className="font-bold text-xl">Balance Amount:</span>
            <span className="font-bold text-xl">
              ${balanceAmount.toFixed(2)}
            </span>
          </div>
        </div>
      </div>

      {/* Notes */}
      {(estimate.estimateData.notes.customer_note ||
        estimate.estimateData.notes.terms_and_conditions) && (
        <div className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-800">Notes</h2>
          {estimate.estimateData.notes.customer_note && (
            <p className="text-gray-700 mt-4">
              <strong>Customer Note:</strong>{" "}
              {estimate.estimateData.notes.customer_note}
            </p>
          )}
          {estimate.estimateData.notes.terms_and_conditions && (
            <p className="text-gray-700 mt-4">
              <strong>Terms & Conditions:</strong>{" "}
              {estimate.estimateData.notes.terms_and_conditions}
            </p>
          )}
        </div>
      )}

      {/* Confirmation Message */}
      {confirmationMessage && (
        <div className="mb-6 text-center">
          <p
            className={`text-lg font-semibold ${
              confirmationMessage.includes("successfully")
                ? "text-green-600"
                : "text-red-600"
            }`}
          >
            {confirmationMessage}
          </p>
        </div>
      )}

      {/* Actions */}
      <div className="flex justify-center space-x-6 mt-10">
        <button
          className="bg-blue-600 text-white px-8 py-3 rounded-md hover:bg-blue-700 transition font-medium"
          onClick={downloadPDF}
        >
          Download PDF
        </button>
        {!estimate.isConfirmed && (
          <button
            className="bg-green-600 text-white px-8 py-3 rounded-md hover:bg-green-700 transition font-medium"
            onClick={handleConfirmClick}
          >
            Confirm
          </button>
        )}
      </div>

      {/* Email Confirmation Modal */}
      {isEmailModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-md shadow-lg max-w-md w-full relative">
            {isConfirming && (
              <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
                <FiLoader className="animate-spin text-4xl text-blue-500" />
              </div>
            )}
            <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">
              Confirm Your Email
            </h2>
            <p className="text-gray-600 mb-6 text-center">
              Please enter your email to confirm the estimate.
            </p>
            <form onSubmit={handleEmailSubmit}>
              <input
                type="email"
                required
                placeholder="Your Email"
                value={emailInput}
                onChange={(e) => setEmailInput(e.target.value)}
                className="w-full border border-gray-300 px-4 py-2 rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
                disabled={isConfirming}
              />
              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  className="px-4 py-2 rounded-md bg-gray-200 hover:bg-gray-300 transition"
                  onClick={() => {
                    setIsEmailModalOpen(false);
                    setConfirmationMessage("");
                  }}
                  disabled={isConfirming}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 rounded-md bg-green-600 text-white hover:bg-green-700 transition"
                  disabled={isConfirming}
                >
                  {isConfirming ? "Confirming..." : "Confirm"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default EstimateDetails;