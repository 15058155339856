import { useEffect,useState } from 'react';
import React from 'react';
import { getPriceListById,getStorePriceListById, globalAlert, globalSuccess } from '../../utils/globalFunctions';
import { useParams } from 'react-router-dom';
import ExcelEditor from '../../components/ExcelEditor';
import { updatePriceList,updateStorePriceList } from '../../utils/globalFunctions';
import { toast } from 'react-toastify';




const PriceListViewPage = ({user}) => {
    const {id} =  useParams()

    const [priceList,setPriceList] = useState([]);

    const [loading,setLoading] = useState(true)
    const [increasePercentage,setIncreasePercentage] = useState(0)

    useEffect(() => {


        if(user?.role === 'store' || user?.role === 'independentStore'){
  
            getStorePriceListById(user.store_id,id).then((res) => {
                setPriceList(res)
                setLoading(false)
            })
        }else{
            getPriceListById(user.manufacturerID,id).then((res) => {
                setPriceList(res)
                setLoading(false)
            })
        }




        
    },[id])

    if(loading){
        return (
            <div>Loading...</div>
        )
    }


    return (
        <div className='p-10'>
            <div className='pt-10 text-2xl font-bold mt-4 mb-4 text-center'>Price List</div>
            <div className='flex w-full items-center mb-2  mx-auto'>
            <p>{priceList.price_list_name}</p>
            <button className="bg-indigo-500 text-white text-sm p-2 ml-4 w-16  rounded" onClick={() => {
                if(user.role === 'store'){
                    updateStorePriceList(user.store_id,id,priceList).then((res) => {
                            if(res){
                                toast.success("Price List Updated")
                            }
                            else{
                            toast.error("Error Updating Price List")
                            }
                        }
                    )
                
                }else{
                    updatePriceList(user.manufacturerID,id,priceList).then((res) => {

                        if(res){
    
                            toast.success("Price List Updated")
                        }
                        else{
                            toast.error("Error Updating Price List")
                        }
                    })
                }
        
            }}>Save</button>
            </div>

      
            <div className='flex w-full justify-end text-sm text-gray-500'>
                <div>
                <p className='ml-2'>Increase by percentage %</p>
                <input
                value={increasePercentage}
                onChange={(e) => setIncreasePercentage(e.target.value)}
                type="number"
                className='border border-gray-300 p-2 rounded'
            />
          
            
                </div>

            </div>

            <ExcelEditor data={priceList}  setPriceList={setPriceList} increase={increasePercentage}/>
        </div>
    );
}

export default PriceListViewPage;