import { PDFDocument, StandardFonts } from "pdf-lib";
import { deepClone } from "./globalFunctions";

const flattenCustomizations = (customizations) => {
  const flattened = {};
  Object.values(customizations).forEach((customization) => {
    Object.entries(customization).forEach(([key, value]) => {
      if (typeof value === "object" && value.details) {
        flattened[key] = value.value;
        value.details.forEach((detail) => {
          flattened[`${key}.${detail.key}`] = detail.value;
        });
      } else {
        flattened[key] = value.value || value;
      }
    });
  });
  return flattened;
};

const handleGenerateLabel = async (
  data,
  orderInfo,
  labelType,
  setOpen,
  setLoadingModal,
  manufacturer
) => {
  const labelSettings = manufacturer.labelSettings;
  const labelSpecs = {
    pageWidth: 612,
    pageHeight: 792,
    labelWidth: 189,
    labelHeight: 72,
    leftMargin: 15.822,
    topMargin: 36,
    gapX: 6.678,
    gapY:0,
    paddingY: 8,
    labelsPerRow: 3,
    labelsPerColumn: 10,
    fontSize: 9,
    topSpace: 2.5,
    lineSpace: 2.75,
  };

  const {
    pageWidth,
    pageHeight,
    labelWidth,
    labelHeight,
    leftMargin,
    topMargin,
    gapX,
    gapY,
    paddingY,
    labelsPerRow,
    labelsPerColumn,
  } = labelSpecs;

  const pdfDoc = await PDFDocument.create();
  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const labelsToSkip =
    parseInt(prompt("How many labels would you like to skip?", "0"), 10) || 0;

  console.log("data", data)
  const flatArray = data
  console.log("data", flatArray)
  const newArray = flatArray.reduce((acc, item) => {
    for (let i = 0; i < item.quantity; i++) {
      acc.push(item);
    }
    return acc;
  }, []);

  console.log("newArray", newArray);  
  const totalLabels = labelsToSkip + newArray.length;
  const labelsPerPage = labelsPerRow * labelsPerColumn;
  const totalPages = Math.ceil(totalLabels / labelsPerPage);

  for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
    const page = pdfDoc.addPage([pageWidth, pageHeight]);

    for (let labelIndex = 0; labelIndex < labelsPerPage; labelIndex++) {
      const globalLabelIndex = pageIndex * labelsPerPage + labelIndex;

      if (globalLabelIndex >= totalLabels) break;

      const actualIndex = globalLabelIndex - labelsToSkip;

      if (actualIndex >= 0 && actualIndex < newArray.length) {
        const item = newArray[actualIndex];

        const row = Math.floor(labelIndex / labelsPerRow);
        const col = labelIndex % labelsPerRow;

        const x = leftMargin + col * (labelWidth + gapX);
        const y = pageHeight - topMargin - row * (labelHeight + gapY);

        if(manufacturer.metricSystem === "inch" && item.metricSystem === "cm"){
          console.log("item", item)
          let inchwidth = convertToFraction(item.width)
          let inchheight = convertToFraction(item.height)
          item.width = inchwidth.whole
          item.height =  inchheight.whole
          item.fractions = {
            width: inchwidth.fraction,
            height: inchheight.fraction
          } 
        }
        else if(manufacturer.metricSystem === "cm" && item.metricSystem === "inch"){
          item.width =  convertToDecimal(item.width, item.fractions?.width)
          item.height =  convertToDecimal(item.height, item.fractions?.height)
        }
      

        const measurements =
          manufacturer.metricSystem === "inch"
            ? `${item.width} ${item.fractions?.width} x ${item.height} ${item.fractions?.height} inch`
            : `${item.width} x ${item.height} cm`;

    
        const dataMapForSettings = {
          "Item No": `Item #${item.index}`,
          "Store Name": orderInfo.store_name,
          "Customer Name": orderInfo.customer_name,
          "Fabric Name": item.fabric_name,
          "Room Name": item.room_name,
          "Measurement": measurements,
          ...flattenCustomizations(item.customizations),
        };
        console.log(dataMapForSettings)

        // Dynamically generate label lines based on labelSettings
        let yOffset = 0;
        labelSettings.forEach((lineConfig) => {
          const lineContent = lineConfig.fields
            .map(
              (field) =>
                `${dataMapForSettings[field.keyName]  ? field.prefix || "" : ""} ${dataMapForSettings[field.keyName] || ""}`
            )
            .join(" ");
          const fontSize = labelSpecs.fontSize; // Default font size, can be adjusted based on `field.size`
          const textWidth = font.widthOfTextAtSize(lineContent, fontSize);
          const textX = x + (labelWidth - textWidth) / 2;

          page.drawText(lineContent, {
            x: textX,
            y: y - paddingY - yOffset,
            size: parseInt(fontSize),
            font,
          });

          yOffset += fontSize + labelSpecs.lineSpace;
        });
      }
    }
  }

  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: "application/pdf" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "labels.pdf";
  link.click();
  setLoadingModal(false);
  setOpen(false);
};





//useMemo maybe
const convertToFraction = (decimal) => {
  console.log("decimal1", decimal);
  if (decimal === "none") {
    return {
      whole: 0,
      fraction: 0,
    };
  }

  console.log("decimal3", decimal);

  let inch_decimal_ =   cmToInches(decimal);
  const wholeNumber = Math.floor(inch_decimal_);
  const fraction = inch_decimal_ - wholeNumber;
  const closestFraction = convertToClosestFraction(fraction, 16); // Using 16 as the maximum denominator

  return {
    whole: wholeNumber,
    fraction: closestFraction,
  };
};

const convertToDecimal = (whole, fraction) => {
  // Convert the whole part to a number
  const wholeNumber = parseFloat(whole);

  // If fraction is a valid fraction string (like "1/4"), split and calculate it
  if (fraction && fraction.includes("/")) {
    const [numerator, denominator] = fraction.split("/").map(Number);
    return wholeNumber + numerator / denominator;
  }

  // If no fraction or fraction is not a valid format, just return the whole number
  return wholeNumber;
};

const inchesToCm = (inches) => {
  return inches * 2.54;
};

const cmToInches = (cm) => {
  return cm / 2.54;
};

const convertToClosestFraction = (fraction, maxDenominator = 16) => {
  const denominator = maxDenominator;
  const numerator = Math.round(fraction * denominator);

  // If the numerator is 0 or equal to the denominator, adjust the fraction
  if (numerator === 0) {
    return "";
  } else if (numerator === denominator) {
    return `${numerator / denominator}`;
  } else {
    // Reduce the fraction to its simplest form
    const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));
    const divisor = gcd(numerator, denominator);
    return `${numerator / divisor}/${denominator / divisor}`;
  }
};


export { handleGenerateLabel };



