import React from 'react';
import {useNavigate} from 'react-router-dom';


let addOns = [
  {
    name:"Store Dashboards",
    description: "Invite dealers or dependent stores to use our store dashboard to place orders.",
    href: "/add-ons/store",
    id:1
  },

  // {
  //   name:"Label Printer",
  //   description: "Easily print labels directly from your inventory management system.",
  //   href: "/add-ons/labelPrinter",
  // },
  {
    name:"Price List CSV",
    description: "Upload and edit your prices using your price list CSV files.",
    href: "/add-ons/priceListLoader",
    id:3
  },
  {
    name:"Wholesale",
    description: "Setup your wholesale customers portal, and manage your wholesale orders.",
    href: "/add-ons/wholesale",
    id:4
  },
  {
    name:"Blinds Production Cut Rules",
    description: "Set up rules to automatically deduct measurement of your production orders.",
    href: "/add-ons/manufacturerCutRules",
    id:2
  },
  {
    name:"Production Sheets & Labels",
    description: "Generate production sheets and labels for your orders.",
    href: "/add-ons/productionSheetsAndLabel",
    id:5
  },


]

const AddOns = ({manufacturer,user}) => {

  const navigate = useNavigate();

  const handleAddOn = (addOn) => {
    console.log(addOn)
  }

    return (
        <div className="pt-12 flex flex-col items-center  min-h-screen">

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full px-8 max-w-6xl">
                {
                  addOns.map((addOn, index) => (
                    <div key={index} className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition-shadow duration-300">
                      <h2 className="text-xl font-semibold text-gray-800">{addOn.name}</h2>
                      <p className="text-gray-600 mt-2">{addOn.description}</p>

                      {
                        manufacturer?.extensions?.includes(addOn.id) ?
                           <button 
                           className="bg-indigo-500 text-white text-sm py-2 px-4 rounded mt-4 hover:bg-indigo-600 transition-colors duration-300"
                           onClick={() => navigate(addOn.href)}
                         >
                           View
                         </button>
                        :
                        <>
                       <button
                        className="bg-indigo-500 text-white text-sm py-2 px-4 rounded mt-4 hover:bg-indigo-600 transition-colors duration-300"
                        onClick={() => handleAddOn(addOn)}
                      >
                       Pricing
                      </button>
                        <button
                        className="ml-2 bg-indigo-500 text-white text-sm py-2 px-4 rounded mt-4 hover:bg-indigo-600 transition-colors duration-300"
                        onClick={() => handleAddOn(addOn)}
                      >
                       Add Now
                      </button>
                        </>
             

                      }
                   
                    </div>
                  ))
                }
            </div>
        </div>
    );
};

export default AddOns;