import React, { useEffect, useState } from "react";
import {
  getGlobalVariations,
  createGlobalVariation,
  globalSuccess,
  globalAlert,
  deleteGlobalVariation,
  updateGlobalVariation,
  fetchManufacturerMaterials,
} from "../../utils/globalFunctions";
import TableComponent from "../../components/TableComponent";
import { useNavigate } from "react-router-dom";
import {getProductIdsByVarianceId,getPriceList} from "../../utils/globalFunctions";
import CustomizationEditorComponent from "../../components/CustomizationEditorComponent";
import ConnectToProducts from "../../components/ConnectToProducts";

export default function CustomizationEditor({ user, manufacturer }) {
  const [customizations, setCustomizations] = useState([]);
  const [customization, setCustomization] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCustomizations, setFilteredCustomizations] = useState([]);
  const [customizationModalOpen, setCustomizationModalOpen] = useState(false);
  const [connectToProductsModalOpen, setConnectToProductsModalOpen] = useState(false);
  const [disconnectToProductsModalOpen, setDisConnectToProductsModalOpen] = useState(false);
  const [materials, setMaterials] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const navigate = useNavigate();



  const rowsPerPage = 8;

  useEffect(() => {
    fetchCustomizations();
    // fetchMaterials();
    fetchPriceLists();
  }, []);

  useEffect(() => {
    checkIsLastPage();
  }, [filteredCustomizations, currentPage]);

  const fetchCustomizations = () => {
    setIsLoading(true);
    getGlobalVariations(user.manufacturerID).then((data) => {
      setCustomizations(data);
      setFilteredCustomizations(data);
      setIsLoading(false);
      setIsLastPage(data.length <= rowsPerPage);
    });
  };

  const fetchMaterials = () => {
    fetchManufacturerMaterials(user.manufacturerID).then((data) => {
      setMaterials(data);
    });
  };

  const fetchPriceLists = () => {
      getPriceList("dnLLP341yLe4HvKOXd8X",user.manufacturerID).then((data) => {
        setPriceList(data);
    });
  };

  const save = (customization) => {
    if (customization.id) {
      updateGlobalVariation(customization).then((result) => {
        if (result) {
          globalSuccess("Customization updated successfully");
        } else {
          globalAlert("Customization update failed");
        }
        fetchCustomizations();
        setCustomizationModalOpen(false);
      });
    } else {
      createGlobalVariation(customization).then((result) => {
        if (result) {
          globalSuccess("Customization created successfully");
        } else {
          globalAlert("Customization creation failed");
        }
        fetchCustomizations();
        setCustomizationModalOpen(false);
      });
    }
  };

  const columns = [
    { Header: "Title", accessor: "variation_title" },
    { Header: "Key", accessor: "key" },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }) => (
        <div className="flex items-center">
          <button
            className="bg-gray-700 hover:bg-gray-800 h-8 p-2 rounded text-white text-xs"
            onClick={() => handleDelete(row.id)}
          >
            Delete
          </button>
          <button
            className="bg-gray-700 ml-2 hover:bg-gray-800 h-8 p-2 rounded text-white text-xs"
            onClick={() => handleView(row)}
          >
            View
          </button>
          <button
            className="bg-gray-700 ml-2 hover:bg-gray-800 h-8 p-2 rounded text-white text-xs"
            onClick={() => {
              setCustomization(row);
              setConnectToProductsModalOpen(true);
            }}
          >
            Connect Products
          </button>
          <button
            className="bg-gray-700 ml-2 hover:bg-gray-800 h-8 p-2 rounded text-white text-xs"
            onClick={() => {
              setCustomization(row);
              setDisConnectToProductsModalOpen(true);
            }}
          >
            Disconnect Products
          </button>
        </div>
      ),
    },
  ];

  const handleView = (customization) => {
    setCustomization(customization);
    setCustomizationModalOpen(true);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    const filtered = customizations.filter((customization) =>
      customization.variation_title.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredCustomizations(filtered);
    setCurrentPage(0);
    checkIsLastPage();
  };

  const checkIsLastPage = () => {
    setIsLastPage(
      (currentPage + 1) * rowsPerPage >= filteredCustomizations.length
    );
  };

  const handleNextPage = () => {
    if (!isLastPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleDelete = (customizationId) => {
    deleteGlobalVariation(customizationId).then((res) => {
      if (res) {
        fetchCustomizations();
      }
    });
  };

  const paginatedData = filteredCustomizations.slice(
    currentPage * rowsPerPage,
    (currentPage + 1) * rowsPerPage
  );

  return (
    <>
      <div className="flex flex-row justify-center">
        <div className="flex flex-col sm:w-1/2">
          <h1 className="text-2xl font-bold">Customizations</h1>
          <div className="flex mt-2">
            <button
              className="bg-gray-700 hover:bg-gray-800 h-8 p-2 rounded text-white text-xs"
              onClick={() => {
                setCustomization({
                  variation_title: "",
                  key: manufacturer?.productionSheetKeys?.length > 0 ? manufacturer.productionSheetKeys[0] : "",
                  creator: user.manufacturerID,
                  created_at: new Date(),
                  isAvailable: true,
                  isDeleted: false,
                  isGlobal: true,
                  isRule: false,
                  isRequired: true,
                  variationItems: [],
                });
                setCustomizationModalOpen(true);
              }}
            >
              Create Customization
            </button>
          </div>
          <div className="flex flex-row justify-center w-full">
            <input
              type="text"
              placeholder="Search by title"
              className="bg-white text-xs p-2 w-full mt-1 rounded border"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col  w-full">
        <div className="sm:pl-8 sm:pr-8 flex flex-wrap justify-center mt-8">
          <div className="w-full overflow-x-auto">
            <div className="table-container">
              <div className="w-full mx-auto">
                <div className="flex justify-between mb-4">
                  <button
                    className="bg-gray-500 hover:bg-gray-700 text-white text-xs font-bold py-2 px-4 rounded"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 0}
                  >
                    Previous
                  </button>
                  <button
                    className="bg-gray-500 hover:bg-gray-700 text-white text-xs font-bold py-2 px-4 rounded"
                    onClick={handleNextPage}
                    disabled={isLastPage}
                  >
                    Next
                  </button>
                </div>
                <TableComponent
                  columns={columns}
                  isLoading={isLoading}
                  data={paginatedData}
                />
              </div>
            </div>
          </div>
        </div>

        {customizationModalOpen && (
          <>
            <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50"></div>
            <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 rounded shadow-lg z-50 w-11/12 max-w-4xl max-h-screen overflow-hidden">
              <div className="h-full w-full flex flex-col items-center justify-start overflow-y-auto max-h-[calc(100vh-4rem)]">
                <CustomizationEditorComponent
                  materials={materials}
                  priceList={priceList}
                  user={user}
                  manufacturer={manufacturer}
                  customization={customization} // Pass the customization for editing
                  onSave={(updatedCustomization) => {
                    save(updatedCustomization);
                  }}
                  onCancel={
                    () => setCustomizationModalOpen(false) // Close the modal
                  }
                />
              </div>
            </div>
          </>
        )}

        {connectToProductsModalOpen && (
          <>
            <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50"></div>
            <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 rounded shadow-lg z-50 w-11/12 max-w-4xl max-h-screen overflow-hidden">
              <div className="h-full w-full flex flex-col items-center justify-start overflow-y-auto max-h-[calc(100vh-4rem)]">
                <ConnectToProducts
                  user={user}
                  manufacturer={manufacturer}
                  customization={customization} // Pass the customization for editing
                  isDisconnect={false}
                  setOpen={setConnectToProductsModalOpen}
                />
              </div>
            </div>
          </>
        )}

    {disconnectToProductsModalOpen && (
          <>
            <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50"></div>
            <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 rounded shadow-lg z-50 w-11/12 max-w-4xl max-h-screen overflow-hidden">
              <div className="h-full w-full flex flex-col items-center justify-start overflow-y-auto max-h-[calc(100vh-4rem)]">
                <ConnectToProducts
                  user={user}
                  manufacturer={manufacturer}
                  customization={customization} // Pass the customization for editing
                  isDisconnect={true}
                  setOpen={setDisConnectToProductsModalOpen}
                />
              </div>
            </div>
          </>
        )}

 
      </div>
    </>
  );
}
