// src/pages/DashboardRedirect.jsx
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../utils/context';
import AdminDashboard from './admin';
import StoreDashboard from './store';
import TestDashboard from './superadmin';

const DashboardRedirect = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  switch (user.role) {
    case 'admin':
      return <AdminDashboard />;
    case 'store':
      return <StoreDashboard />;
    case 'independentStore':
        return <StoreDashboard />;
    case 'superadmin':
      return <TestDashboard />;
    default:
      return <div>Undefined role</div>;
  }
};

export default DashboardRedirect;