import React, { useEffect, useState } from "react";
import { useTable } from "react-table";
import {
  getPriceList,
  globalSuccess,
  getStorePriceLists,
} from "../utils/globalFunctions";
import { deletePriceList } from "../utils/globalFunctions";
import { useNavigate } from "react-router-dom";

const PriceListView = ({ user, uploaded, setUploaded }) => {
  const [priceList, setPriceList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      if (user.role === "admin") {
        getPriceList("dnLLP341yLe4HvKOXd8X", user.manufacturerID).then(
          (res) => {
            res.sort((a, b) =>
              a.price_list_name.localeCompare(b.price_list_name)
            );
            setPriceList(res);
          }
        );
      } else {
        getStorePriceLists(user.store_id).then((res) => {
          console.log(res);
          // order alphabetically for price_list_name field
          res.sort((a, b) =>
            a.price_list_name.localeCompare(b.price_list_name)
          );
          setPriceList(res);
        });
      }
    }
  }, [user]);

  useEffect(() => {
    if (uploaded && user && user.role === "admin") {
      getPriceList("dnLLP341yLe4HvKOXd8X", user.manufacturerID).then((res) => {
        // order alphabetically for price_list_name field
        res.sort((a, b) => a.price_list_name.localeCompare(b.price_list_name));
        setPriceList(res);
        setUploaded(false);
      });
    }
  }, [uploaded]);

  return (
    <div className="mt-4">
      <table className="min-w-full divide-y divide-gray-200 table-fixed">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Name
            </th>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {priceList.map((price) => (
            <tr key={price.id}>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                {price.price_list_name}
              </td>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
          
          {
            (user.role === "store" || user.role === "independentStore")  &&
            <button
            className="bg-indigo-500 text-white text-sm p-2 rounded"
            onClick={() => {
              navigate(`/priceListLoader/${price.id}`);
            }}
          >
            Update
          </button>
          }

                {user.role === "admin" && (
                    <>
                          <button
                  className="bg-indigo-500 text-white text-sm p-2 rounded"
                  onClick={() => {
                    navigate(`/add-ons/priceListLoader/${price.id}`);
                  }}
                >
                  Update
                </button>
                         <button
                    className="bg-indigo-500 text-white text-sm p-2 rounded ml-2"
                    onClick={() => {
                      deletePriceList(user.manufacturerID, price.id).then(
                        () => {
                          getPriceList(
                            "dnLLP341yLe4HvKOXd8X",
                            user.manufacturerID
                          ).then((res) => {
                            res.sort((a, b) =>
                              a.price_list_name.localeCompare(b.price_list_name)
                            );
                            setPriceList(res);
                            globalSuccess("Price List Deleted Successfully");
                          });
                        }
                      );
                    }}
                  >
                    delete
                  </button>
                    </>
             
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PriceListView;
