import OrderListAdmin from "../../components/OrderListAdmin";
import { useState, useEffect } from "react";
import axios from "axios";
import {
  globalAlert,
  removeDuplicateArrays,
} from "../../utils/globalFunctions";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchBox from "../../components/SearchBox";
import { addressURL } from "../../utils/globalFunctions";
export default function Orders({ user, route }) {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState(null);
  const [ordersLoading, setOrdersLoading] = useState(true);
  const [newOrderEntered, setNewOrderEntered] = useState(false);
  const [renderIndex, setRenderIndex] = useState(0);
  const [more, setMore] = useState(true);
  const [products, setProducts] = useState([]);

  const [rules, setRules] = useState([]);

  useEffect(() => {
    axios
      .get(addressURL + "/api/getRules", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        setRules(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(addressURL + `/api/price-list?fromWhere=allProducts`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        setProducts(res.data.result);
      })
      .catch((err) => console.log(err));
  }, []);

  async function getOrderWithGroupByCustomerID() {
    console.log(renderIndex);
    try {
      const res = await axios.get(
        addressURL +
          `/api/getOrders?index=${renderIndex + 1}&limit=${
            renderIndex + 2
          }&route=${route}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      if (res.data.data.length === 0) {
        setRenderIndex(0);
        setMore(false);
        return;
      } else if (res.data.data.length === 1) {
        setMore(false);
        let newOrders = [...orders, ...res.data.data];
        setOrders(removeDuplicateArrays(newOrders));
        setRenderIndex(0);
      } else {
        let newOrders = [...orders, ...res.data.data];
        setOrders(removeDuplicateArrays(newOrders));
        setMore(true);
        setRenderIndex(renderIndex + 2);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setOrdersLoading(false);
    }
  }

  useEffect(() => {
    if (products.length > 0) {
      getOrderWithGroupByCustomerID();
    }
  }, [products]);

  const fetchMoreData = () => {
    getOrderWithGroupByCustomerID();
  };

  // useEffect(() => {
  //   if (newOrderEntered) {
  //     getOrderWithGroupByCustomerID();
  //     setNewOrderEntered(false);
  //   }
  // }, [newOrderEntered]);

  function LoadingSkeleton() {
    return (
      <div className="animate-pulse mt-12 pl-8">
        <div className="flex flex-col mb-2">
          <div className="h-4 bg-gray-400 rounded w-1/3 mb-1"></div>
          <div className="h-4 bg-gray-400 rounded w-1/4"></div>
        </div>
        <div className="border-collapse border border-gray-200 w-full mb-2">
          {[...Array(4)].map((_, rowIndex) => (
            <div key={rowIndex} className="flex">
              {[...Array(window.innerWidth < 700 ? 4 : 10)].map(
                (_, colIndex) => (
                  <div
                    key={colIndex}
                    className="h-10  bg-gray-400  rounded mr-1 mb-1 flex-grow border border-gray-200"
                  ></div>
                )
              )}
            </div>
          ))}
        </div>
        <div className="flex flex-col mb-2 mt-16">
          <div className="h-4 bg-gray-400 rounded w-1/4"></div>
        </div>
        <div
          className={
            window.innerWidth < 700
              ? "border-collapse border border-gray-200 w-full "
              : "border-collapse border border-gray-200 w-1/2 "
          }
        >
          {[...Array(3)].map((_, rowIndex) => (
            <div key={rowIndex} className="flex">
              {[...Array(window.innerWidth < 700 ? 3 : 5)].map(
                (_, colIndex) => (
                  <div
                    key={colIndex}
                    className="h-10 bg-gray-400 rounded mr-1 mb-1 flex-grow border border-gray-200"
                  ></div>
                )
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }

  return rules ? (
    <div>
      <SearchBox
        user={user}
        rules={rules}
        getOrderWithGroupByCustomerID={getOrderWithGroupByCustomerID}
      />

      <p className="text-xl font-bold text-center mb-[-1rem] mt-4">
        {route === "active-orders" ? "Active Orders" : "Completed Orders"}
      </p>

      {ordersLoading ? (
        <>
          <LoadingSkeleton />
          <LoadingSkeleton />
        </>
      ) : orders && orders.length > 0 && products.length > 0 ? (
        <>
          <InfiniteScroll
            dataLength={orders.length} //This is important field to render the next data
            next={fetchMoreData}
            hasMore={more}
            loader={<h4 className="text-center mb-4">Loading...</h4>}
            endMessage={
              <p className="text-center mb-4">
                <b>No more orders</b>
              </p>
            }
            // below props only if you need pull down functionality
            // refreshFunction={this.refresh}
            // pullDownToRefresh
            // pullDownToRefreshThreshold={50}
            // pullDownToRefreshContent={
            //   <h3 style={{ textAlign: "center" }}>
            //     &#8595; Pull down to refresh
            //   </h3>
            // }
            // releaseToRefreshContent={
            //   <h3 style={{ textAlign: "center" }}>
            //     &#8593; Release to refresh
            //   </h3>
            // }
          >
            {orders.map((order, index) => {
              return (
                <OrderListAdmin
                  customerID={order.customerID}
                  orders={order.orders}
                  totalPrice={order.totalPrice}
                  extras={order.extras}
                  rules={rules}
                  setNewOrderEntered={setNewOrderEntered}
                  index={index}
                  getOrderWithGroupByCustomerID={getOrderWithGroupByCustomerID}
                  products={products}
                />
              );
            })}
          </InfiniteScroll>
        </>
      ) : (
        <h1 className="w-full h-48 flex justify-center items-center text-2xl text-gray-500 font-bold">
          No orders yet
        </h1>
      )}
    </div>
  ) : null;
}
