import { Fragment, useState,useEffect } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  ArrowDownCircleIcon,
  ArrowPathIcon,
  ArrowUpCircleIcon,
  Bars3Icon,
  EllipsisHorizontalIcon,
  PlusSmallIcon,
} from '@heroicons/react/20/solid'
import { BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
import { getStoreStats,getTop3SellingStoreProducts,getTrendingProducts ,getRecentCustomers} from '../../utils/firebase'
import {getGlobalStats} from '../../utils/globalFunctions'

const navigation = [
  { name: 'Home', href: '#' },
  { name: 'Invoices', href: '#' },
  { name: 'Clients', href: '#' },
  { name: 'Expenses', href: '#' },
]
const secondaryNavigation = [
  // { name: 'Last 7 days', href: '#', current: true },
  // { name: 'Last 30 days', href: '#', current: false },
  { name: 'All-time', href: '#', current: true },
]

const statuses = {
  Paid: 'text-green-700 bg-green-50 ring-green-600/20',
  Withdraw: 'text-gray-600 bg-gray-50 ring-gray-500/10',
  Overdue: 'text-red-700 bg-red-50 ring-red-600/10',
}


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Home({user}) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [top3,setTop3] = useState(null)
  const [trending,setTrendingProducts] = useState(null)
  const [recentCustomers,setRecentCustomers] = useState(null)
  const [stats, setStats] = useState([])


  const navigate  = useNavigate()

  useEffect(() => { 
        if(user?.manufacturer === "Cachet Admin"){

        const stats = [
          { name: 'Customer Total', value: 'customerTotalAmount', suffix: 'CAD',  changeType: 'positive' },
          { name: 'Manufacturer Total', value: 'manufacturerTotalAmount',suffix: 'CAD', changeType: 'negative' },
          { name: 'Order Count', value: 'orderCount', suffix: '',   changeType: 'positive' },
          // { name: 'Expenses', value: '$30,156.00', change: '+10.18%', changeType: 'negative' },
        ]
        setStats(stats)

    }else{
      const stats = [

        { name: 'Total Order Value', value: 'manufacturerTotalAmount',suffix: 'CAD', changeType: 'negative' },
        { name: 'Order Count', value: 'orderCount', suffix: '',   changeType: 'positive' },
        // { name: 'Expenses', value: '$30,156.00', change: '+10.18%', changeType: 'negative' },
      ]
      setStats(stats)
    }
  }, [user])
  useEffect(()=>{
    getGlobalStats().then((data)=>{
      console.log(data)
      setTrendingProducts(data)
    })
  },[])

  useEffect(() => {
    
    getStoreStats({storeId:user?.store_id}).then(({data}) => {
    }).catch((err)=>{
        console.log(err)
    })




      getRecentCustomers({storeId:user?.store_id}).then(({data}) => {
        setRecentCustomers(data)
      }).catch((err)=>{
        console.log(err)
      })


      getTop3SellingStoreProducts({storeId:user?.store_id})


      getTrendingProducts({storeId:user?.store_id})
    
     


  }, []);

  return (
    <>


      <main>
        <div className="relative isolate overflow-hidden pt-16">
          {/* Secondary navigation */}
          <header className="pb-4 pt-6 sm:pb-6">
            <div className="mx-auto flex max-w-7xl flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
              <h1 className="text-base font-semibold leading-7 text-gray-900">{user?.storeName}</h1>
              <div className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
                {secondaryNavigation?.map((item) => (
                  <a key={item.name} href={item.href} className={item.current ? 'text-indigo-600' : 'text-gray-700'}>
                    {item.name}
                  </a>
                ))}
              </div>
              <button
              onClick={()=>{
                navigate('/order')
              }}
                className="new-order-button  ml-auto flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <PlusSmallIcon className="-ml-1.5 h-5 w-5" aria-hidden="true" />
              New Order
              </button>
            </div>
          </header>

          {/* Stats */}
          <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
            <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
              {stats?.map((stat, statIdx) => (
                <div
                  key={stat.name}
                  className={classNames(
                    statIdx % 2 === 1 ? 'sm:border-l' : statIdx === 2 ? 'lg:border-l' : '',
                    'flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8'
                  )}
                >
                  <dt className="text-sm font-medium leading-6 text-gray-500">{stat.name}</dt>
                  <dd
                    className={classNames(
                      stat.changeType === 'negative' ? 'text-rose-600' : 'text-gray-700',
                      'text-xs font-medium'
                    )}
                  >
                    {stat.change}
                  </dd>
                  <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                    {user?.storeStats &&  user?.storeStats[stat.value]} <a className='
                    text-base font-medium text-gray-500'
                    >{stat.suffix}</a>
                  </dd>
                </div>
              ))}
            </dl>
          </div>

          <div
            className="absolute left-0 top-full -z-10 mt-96 origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50"
            aria-hidden="true"
          >
            <div
              className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#FF80B5] to-[#9089FC]"
              style={{
                clipPath:
                  'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
              }}
            />
          </div>
        </div>

        <div className="space-y-8 py-8 xl:space-y-16">
          {/* Recent activity table*/}
          {/* <div>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h2 className="mx-auto max-w-2xl text-base font-semibold leading-6 text-gray-900 lg:mx-0 lg:max-w-none">
               Store's Top 3 Selling Products
              </h2>
            </div>
            <div className="mt-6 overflow-hidden border-t border-gray-100">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                  <table className="w-full text-left">
                    <thead className="sr-only">
                      <tr>
                        <th>Product Title</th>
                        <th className="hidden sm:table-cell">Client</th>
                        <th>More details</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-100">
                 
                      {
                       user?.storeStats && user?.storeStats?.top3SellingProducts?.map((item)=>{
                          return (
                            <tr>
                            <td className="py-4 ">
                              {item.product_title}  <a className='text-xs'>({item.quantity} units sold)    </a>  
                            </td>
                  
                          </tr>
                          )
                        }
                        )
                      }
            
                    
                    </tbody>
           

               
                    

                
                  </table>
                </div>
              </div>
            </div>
          </div> */}

{/* 
          <div>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h2 className="mx-auto max-w-2xl text-base font-semibold leading-6 text-gray-900 lg:mx-0 lg:max-w-none">
               Manufacturer Trending Products
              </h2>
            </div>
            <div className="mt-6 overflow-hidden border-t border-gray-100">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                  <table className="w-full text-left">
                    <thead className="sr-only">
                      <tr>
                        <th>Product Title</th>
                 
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-100">
                 
                          {
                            trending && trending?.map((item)=>{
                              return (
                                <tr>
                                <td className="py-4 ">
                                  {item.product_title}  <a className='text-xs'>({item.quantity} units sold)    </a>  
                                </td>
                      
                              </tr>
                              )
                            }
                            )
                          }
                    
                    </tbody>
                    
            
           

               
                    

                
                  </table>
                </div>
              </div>
            </div>
          </div> */}
          {/* Recent client list*/}
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
              <div className="flex items-center justify-between">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Recent Orders</h2>
                <a href="/orders" className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                  View all<span className="sr-only">, orders</span>
                </a>
              </div>
              {recentCustomers && recentCustomers.length === 0 && <>
                <div className="mt-6 text-sm text-center text-gray-900">No recent orders found</div>

              </>}
              <ul role="list" className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                {recentCustomers?.map((client) => (
                  <li key={client.order_id} className="overflow-hidden rounded-xl border border-gray-200">
                    <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                  
                      <div className="text-sm font-medium leading-6 text-gray-900">{client.customer_name}</div>
                      <Menu as="div" className="relative ml-auto">
                        <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                          <span className="sr-only">Open options</span>
                          <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(
                                    active ? 'bg-gray-50' : '',
                                    'block px-3 py-1 text-sm leading-6 text-gray-900'
                                  )}
                                >
                                  View<span className="sr-only">, {client.name}</span>
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(
                                    active ? 'bg-gray-50' : '',
                                    'block px-3 py-1 text-sm leading-6 text-gray-900'
                                  )}
                                >
                                  Edit<span className="sr-only">, {client.customer_name}</span>
                                </a>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                    <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                      <div className="flex justify-between gap-x-4 py-3">
                        <dt className="text-gray-500">Created At</dt>
                        <dd className="text-gray-700">
                          <TimeComponent client={client} />
                        </dd>
                      </div>
                      <div className="flex justify-between gap-x-4 py-3">
                        <dt className="text-gray-500">Amount</dt>
                        <dd className="flex items-start gap-x-2">
                          <div className="font-medium text-gray-900">{client.total}</div>
                          <div
                            className={classNames(
                              statuses[client.status],
                              'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset'
                            )}
                          >
                            {client.status}
                          </div>
                        </dd>
                      </div>
                    </dl>
                  </li>

                ))

              }
              </ul>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}


const TimeComponent = ({ client }) => {
  let dateTimeAttr = '';
  let displayDate = '';

  if (client && client.created_at instanceof Date) {
    dateTimeAttr = client.created_at.toISOString();
    displayDate = client.created_at.toLocaleDateString();
  }

  return (
    <div>
      <time dateTime={dateTimeAttr}>{displayDate}</time>
    </div>
  );
};

