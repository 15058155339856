import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { UserProvider,ManufacturerProvider } from './utils/context';
import SnackbarProvider from 'react-simple-snackbar'
import Notification from './components/Notification';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TourGuide from './components/TourGuide';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

    <SnackbarProvider>
        <UserProvider>
            <ManufacturerProvider>
                <Notification />
                <ToastContainer autoClose={2000}  hideProgressBar />
                <App />
            </ManufacturerProvider>
        </UserProvider>
    </SnackbarProvider>

);


serviceWorkerRegistration.unregister();
