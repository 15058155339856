import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  SettingsIcon,
  EyeDropperIcon,
  XMarkIcon,
  ChatBubbleBottomCenterTextIcon,
  Cog8ToothIcon,
  CubeIcon,
  ScaleIcon,
  BuildingStorefrontIcon,
  BuildingOffice2Icon,
  Square3Stack3DIcon,
  TruckIcon,
  PhoneIcon

} from '@heroicons/react/24/outline'
import { ChevronDownIcon, ReceiptPercentIcon } from '@heroicons/react/20/solid'
import Logout from "../../components/logout";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PriceList from "./pricelist";
import { Link } from 'react-router-dom';
import { useUser } from "../../utils/context";
import Orders from "./orders";
import Dealers from "./dealers";
import Dealer from "./dealer";
import Settings from "./settings";
import ProfileSettings from "./profileSettings";
import ProductionSettings from "./productionSettings";
import Details from "./details";
import ConnectBank from "./connectBank";
import Invoices from "./invoices";
import NewOrders from "../store/orderEntry";
import { useEffect } from "react";
import Notify from "./notify";
import ManufacturerOrder from "./ManufacturerOrder";
import NewRules from "./newRules";
import OrderEntries from "../store/OrderEntries";
import { databaseMigrate } from "../../utils/globalFunctions";
import Products from "./products";
import ProductCustomizations from "./productCustomizations";
import CreateProduct from "./createProduct";
import CustomizationEditor from "./CustomizationEditor";
import Statistics from './statistics';
import Teams from './teams';
import Reports from './Reports';
import AddOns from './addOns';
import CreateSeller from './create-seller';
import SignOut from './signOut';
import PriceExcelLoader from '../../extensions/priceExcelLoder';
import ManufacturerCutRules from '../../extensions/ManufacturerRules';
import ManufacturerProduct from './manufacturerProduct';
import ProductEdit from './productEdit';
import Chats from './chats';
import Discounts from './discounts';
import CreateDiscount from './createDiscount';
import EditDiscount from './editDiscount';
import PriceListViewPage from '../../extensions/priceExcelLoder/PriceListViewPage';
import { WhiteLogo,BlackLogo } from '../../components/Logo';
import { handleSignOut } from '../../utils/helperFunctions';
import Materials from './materials';
import CreateMaterial from './createMaterial';
import VirtualConfigurator from './virtualConfigurator';
import Suppliers from './suppliers';
import MaterialEdit from './materialEdit';
import Wholesale from './wholesale';

import ProductionSheetsAndLabel from '../../extensions/productionSheetsAndLabels'
const teams = [
  { id: 1, name: 'OZZO BLINDS', href: '#', initial: 'H', current: false },
  { id: 2, name: 'Sk Blinds', href: '#', initial: 'T', current: false },
  { id: 3, name: 'Cachet Blinds', href: '#', initial: 'W', current: false },
]
const userNavigation = [
  // { name: 'Your profile', href: '#' },
  { name: 'Sign out', onClick: () => {
    handleSignOut()
  }
  },

]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const nav = [
  // { name: 'Dashboard', href: '/', icon: HomeIcon, current: true },
  { name: 'Orders', href: '/orders', icon: FolderIcon, current: false },
  { name: 'Stores', href: '/teams', icon: UsersIcon, current: false },
  // { name: 'Suppliers', href: '/suppliers', icon: PhoneIcon, current: false },
  { name: 'Product Inventory', href: '/products', icon: CalendarIcon, current: false },
  { name: 'Material Inventory', href: '/materials', icon: Square3Stack3DIcon, current: false },
  { name: 'Discounts', href: '/discounts', icon: ReceiptPercentIcon, current: false },
  // { name: 'Chats', href: '/chats', icon: ChatBubbleBottomCenterTextIcon, current: false },
  { name: 'Customization Editor', href: '/customization-editor', icon: EyeDropperIcon, current: false },
  { name: 'Extensions', href: '/add-ons', icon: DocumentDuplicateIcon, current: false },
  { name: 'Settings', href: '/settings', icon: Cog8ToothIcon, current: false },
  // { name: 'Reports', href: '/reports', icon: ChartPieIcon, current: false },
]
export default function Dashboard({manufacturer,setManufacturer}) {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [navigation, setNavigation] = useState(nav)
  const [pathname, setPathname] = useState(window.location.pathname)
  const { user,setUser } = useUser();


  useEffect(() => {

    // check the route and set the current route to true
    const currentRoute = pathname
    const newNav = navigation.map((item) => {
      if (item.href === currentRoute) {
        return { ...item, current: true }
      }
      return { ...item, current: false }
    })
    setNavigation(newNav)
  }, [pathname])

  if(!user){
    return <div>Loading...</div>
  }






  return (
    <div className="flex flex-col  min-h-[90vh]">
        <div >
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            {/* Separator */}
            <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />
            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">

              <div className="flex items-center justify-between  w-full gap-x-4 lg:gap-x-6">
                <button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                <div className=" flex items-center gap-x-2">
                {/* Separator */}
                <a 
                className="text-sm font-semibold leading-6 text-gray-900"
                data-canny-link href="https://supply-circle.canny.io">
            Give feedback
          </a>
                <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true" />

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="h-8 rounded-full bg-gray-50"
                      src={manufacturer.logo}
                      alt=""
                    />
                    <span className="hidden lg:flex lg:items-center">
                      <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                        {manufacturer?.displayName}
                      </span>
                      <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              onClick={item.onClick}
                              className={classNames(
                                active ? 'bg-gray-50' : '',
                                'block px-3 py-1 text-sm leading-6 text-gray-900'
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
                </div>
              </div>
            </div>
          </div>






            <Transition.Root show={sidebarOpen} as={Fragment}>
              <Dialog className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-900/80" />
                </Transition.Child>

                <div className="fixed inset-0 flex">
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                  >
                    <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                          <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                            <span className="sr-only">Close sidebar</span>
                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                          </button>
                        </div>
                      </Transition.Child>
                      {/* Sidebar component, swap this element with another sidebar if you like */}
                      <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-600 px-6 pb-4">
                        <div className="flex h-16 shrink-0 items-center">
                          <WhiteLogo width={150}/>
                        </div>
                        <nav className="flex flex-1 flex-col">
                          <ul role="list" className="flex flex-1 flex-col gap-y-7">
                            <li>
                              <ul role="list" className="-mx-2 space-y-1">
                                {navigation.map((item) => (
                                  <li key={item.name}>
                                    <Link
                                      to={item.href}
                                      onClick={() => setPathname(item.href)}
                                      className={classNames(
                                        item.current
                                          ? 'bg-indigo-700 text-white'
                                          : 'text-indigo-200 hover:text-white hover:bg-indigo-700',
                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                      )}
                                    >
                                      <item.icon
                                        className={classNames(
                                          item.current ? 'text-white' : 'text-indigo-200 group-hover:text-white',
                                          'h-6 w-6 shrink-0'
                                        )}
                                        aria-hidden="true"
                                      />
                                      {item.name}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </li>
     
                          </ul>
                        </nav>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-600 px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">
              <WhiteLogo width={150}/>
            </div>
          
 
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          onClick={() => setPathname(item.href)}
                          className={classNames(
                            item.current
                              ? 'bg-indigo-700 text-white'
                              : 'text-indigo-200 hover:text-white hover:bg-indigo-700',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current ? 'text-white' : 'text-indigo-200 group-hover:text-white',
                              'h-6 w-6 shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                {/* <li>
                  <div className="text-xs font-semibold leading-6 text-indigo-200">Your teams</div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {teams.map((team) => (
                      <li key={team.name}>
                        <a
                          href={team.href}
                          className={classNames(
                            team.current
                              ? 'bg-indigo-700 text-white'
                              : 'text-indigo-200 hover:text-white hover:bg-indigo-700',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-indigo-400 bg-indigo-500 text-[0.625rem] font-medium text-white">
                            {team.initial}
                          </span>
                          <span className="truncate">{team.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="mt-auto">
                  <a
                    href="#"
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-indigo-700 hover:text-white"
                  >
                    <Cog6ToothIcon
                      className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
                      aria-hidden="true"
                    />
                    Settings
                  </a>
                </li> */}
              </ul>
            </nav>
          </div>
        </div>


        <main className="py-10 lg:pl-72">
            <div className="px-4 sm:px-6 lg:px-8">
              <Routes>
                <Route path="/" element={<ManufacturerOrder  user={user} manufacturer={manufacturer} />} />
                {/* <Route path="/" element={<Dashboard user={user} />} /> */}
                <Route path="/dealers" element={<Dealers user={user} />} />
                <Route path="/suppliers" element={<Suppliers user={user} manufacturer={manufacturer} />} />
                <Route path="/create-product" element={<CreateProduct user={user} manufacturer={manufacturer} />} />
                <Route path="/create-material" element={<CreateMaterial user={user} manufacturer={manufacturer} />} />
                <Route path="/virtual-configurator" element={<VirtualConfigurator user={user} manufacturer={manufacturer} />} />
                <Route path="/orders" element={<ManufacturerOrder user={user} manufacturer={manufacturer} />} />
                <Route path="/new-rules" element={<NewRules user={user} />} />
                <Route path="/products" element={<Products user={user} />} />
                <Route path="/materials" element={<Materials user={user} />} />
                <Route path="/product/:id" element={<ManufacturerProduct user={user} manufacturer={manufacturer} />} />
                <Route path="/teams" element={<Teams user={user} manufacturer={manufacturer} />} />
                <Route path="/customizations" element={<ProductCustomizations user={user} />} />
                <Route
                  path="/edit/order/:order_id"
                  element={<OrderEntries user={user} />}
                />
                <Route path="/add-ons/productionSheetsAndLabel" element={<ProductionSheetsAndLabel user={user} manufacturer={manufacturer} />} />
                <Route path="/add-ons/store" element={<Teams user={user} manufacturer={manufacturer} />} />
                <Route path="/add-ons/wholesale" element={<Wholesale user={user} manufacturer={manufacturer} />} />
                <Route path="/add-ons/priceListLoader" element={<PriceExcelLoader user={user} />} />
                <Route path="/add-ons/priceListLoader/:id" element={<PriceListViewPage user={user} />} />
                <Route path="/add-ons/manufacturerCutRules" element={<ManufacturerCutRules user={user} manufacturer={manufacturer} />} />
                {/* <Route path="/invoices" element={<Invoices user={user} />} /> */}
                <Route path="/chats" element={<Chats user={user} manufacturer={manufacturer}/>} />
                <Route path="/discounts" element={<Discounts user={user} manufacturer={manufacturer} />} />
                <Route path="/create-seller" element={<CreateSeller user={user} />} />
                <Route path="/reports" element={<Reports user={user} />} />
                <Route path="/add-ons" element={<AddOns user={user} manufacturer={manufacturer}/>} />
                <Route
                  path="/dealers/active-orders" 
                  element={<Orders user={user} route={"active-orders"} />}
                />
                <Route
                  path="/dealers/completed-orders"
                  element={<Orders user={user} route={"completed-orders"} />}
                />
                <Route path="/dealers/details" element={<Details user={user} />} />
                <Route path="/dealers/:storename" element={<Dealer user={user} />} />
                <Route path="/dealers/notify" element={<Notify user={user} />} />
                <Route path="/settings" element={<Settings user={user} manufacturer={manufacturer} setManufacturer={setManufacturer} />} />
                <Route path="/create-discount" element={<CreateDiscount user={user} manufacturer={manufacturer} />} />
                <Route path="/edit-discount/:id" element={<EditDiscount user={user} manufacturer={manufacturer} />} />
                <Route path="/edit/product/:id" element={<ProductEdit manufacturer={manufacturer}/>} />
                <Route path="/edit/material/:id" element={<MaterialEdit manufacturer={manufacturer}/>} />
                <Route path="/teams/:id" element={<Dealer user={user} manufacturer={manufacturer} />} />
{/* 
                <Route
                  path="/settings/profile"
                  element={<ProfileSettings user={user} />}
                />
                <Route
                  path="/settings/quickbooks"
                  element={<ConnectBank user={user} />}
                />
                <Route
                  path="/settings/production"
                  element={<ProductionSettings user={user} />}
                /> */}
                  <Route
                  path="/customization-editor"
                  element={<CustomizationEditor user={user} manufacturer={manufacturer} />}
                />
                <Route
                  path="/sign-out"
                  element={<SignOut />}
                />
                <Route
                  path="*"
                  element={
                    <div className="flex flex-col justify-center items-center h-screen">
                      <h1 className="text-4xl text-gray-600 font-bold">
                        404 Not Found
                      </h1>
                      <button
                        onClick={() => {
                          // go back on window
                          window.history.back();
                        }}
                        className="bg-gray-600 mt-4 text-sm hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                      >
                        Go Back
                      </button>
                    </div>
                  }
                />
              </Routes>
              </div>
          </main>
  
      
        </div>
    </div>
  );
}
