import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";

export default function CustomizationEditorComponent({
  user,
  materials,
  priceList,
  manufacturer,
  customization: initialCustomization,
  onSave,
  onCancel,
}) {
  const [customization, setCustomization] = useState(initialCustomization);
  const [expandedSections, setExpandedSections] = useState({}); // Track expanded/collapsed state
  const [expandedDetails, setExpandedDetails] = useState({}); // Track expanded/collapsed state for details

  useEffect(() => {
    setCustomization(initialCustomization);
  }, [initialCustomization]);

  const handleInputChange = (field, value) => {
    
    setCustomization((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleItemChange = (index, field, value) => {
    const updatedItems = [...customization.variationItems];
    updatedItems[index][field] = value;

    setCustomization((prev) => ({
      ...prev,
      variationItems: updatedItems,
    }));
  };

  const handleDetailChange = (itemIndex, detailIndex, field, value) => {
    const updatedItems = [...customization.variationItems];
    updatedItems[itemIndex].variation_details[detailIndex][field] = value;

    setCustomization((prev) => ({
      ...prev,
      variationItems: updatedItems,
    }));
  };

  const handleDetailItemChange = (
    itemIndex,
    detailIndex,
    detailItemIndex,
    field,
    value
  ) => {
    const updatedItems = [...customization.variationItems];
    updatedItems[itemIndex].variation_details[detailIndex].variation_detail_items[
      detailItemIndex
    ][field] = value;

    setCustomization((prev) => ({
      ...prev,
      variationItems: updatedItems,
    }));
  };

  const addNewVariationItem = () => {
    const variation_name = window.prompt("Enter new variation item name");
    const variation_price = window.prompt("Enter new variation item price");

    if (!variation_name || !variation_price) return;

    const newItem = {
      id: uuid(),
      variation_name,
      variation_price,
      isAvailable: true,
      variation_details: [],
    };

    setCustomization((prev) => ({
      ...prev,
      variationItems: [...prev.variationItems, newItem],
    }));
  };

  const addNewVariationDetail = (itemIndex) => {
    const option_title = window.prompt("Enter new option title for this item");

    if (!option_title) return;

    const newDetail = {
      id: uuid(),
      variation_detail_title: option_title,
      variation_detail_items: [],
      isAvailable: true,
    };

    const updatedItems = [...customization.variationItems];
    updatedItems[itemIndex].variation_details.push(newDetail);

    setCustomization((prev) => ({
      ...prev,
      variationItems: updatedItems,
    }));
  };

  const addNewVariationDetailItem = (itemIndex, detailIndex) => {
    const option_name = window.prompt("Enter new option name");
    const option_price = window.prompt("Enter new option price");



    if (!option_name || !option_price) return;

    const newDetailItem = {
      id: uuid(),
      variation_detail_name: option_name,
      variation_detail_price: option_price,
      isAvailable: true,
    };

    const updatedItems = [...customization.variationItems];
    updatedItems[itemIndex].variation_details[
      detailIndex
    ].variation_detail_items.push(newDetailItem);

    setCustomization((prev) => ({
      ...prev,
      variationItems: updatedItems,
    }));
  };

  const handleDeleteVariationItem = (itemIndex) => {
    const updatedItems = customization.variationItems.filter(
      (_, index) => index !== itemIndex
    );
    setCustomization((prev) => ({
      ...prev,
      variationItems: updatedItems,
    }));
  };

  const handleDeleteVariationDetail = (itemIndex, detailIndex) => {
    const updatedDetails = customization.variationItems[itemIndex].variation_details.filter(
      (_, index) => index !== detailIndex
    );
    const updatedItems = [...customization.variationItems];
    updatedItems[itemIndex].variation_details = updatedDetails;

    setCustomization((prev) => ({
      ...prev,
      variationItems: updatedItems,
    }));
  };

  const handleDeleteVariationDetailItem = (
    itemIndex,
    detailIndex,
    detailItemIndex
  ) => {
    const updatedDetailItems = customization.variationItems[itemIndex].variation_details[
      detailIndex
    ].variation_detail_items.filter((_, index) => index !== detailItemIndex);

    const updatedItems = [...customization.variationItems];
    updatedItems[itemIndex].variation_details[
      detailIndex
    ].variation_detail_items = updatedDetailItems;

    setCustomization((prev) => ({
      ...prev,
      variationItems: updatedItems,
    }));
  };

const handleSave = () => {
  // Validate customization title and key
  if (!customization.variation_title || customization.variation_title.trim() === "") {
    alert("Customization title is required.");
    return;
  }



  if (!customization.key || customization.key.trim() === "") {
    alert("Customization key is required.");
    return;
  }

  if(customization.variationItems.length === 0){
    alert("Please add at least one variation item.");
    return;
  }

  // Validate each variation item
  for (let i = 0; i < customization.variationItems.length; i++) {
    const item = customization.variationItems[i];

    if (!item.variation_name || item.variation_name.trim() === "") {
      alert(`Variation item ${i + 1} is missing a name.`);
      return;
    }

    if (!isValidPrice(item.variation_price)) {
      alert(`Variation item ${i + 1} has an invalid price. Please enter a positive number greater than zero.`);
      return;
    }

    // Validate each variation detail
    for (let j = 0; j < item.variation_details.length; j++) {
      const detail = item.variation_details[j];

      if (!detail.variation_detail_title || detail.variation_detail_title.trim() === "") {
        alert(`Detail ${j + 1} in variation item ${i + 1} is missing a title.`);
        return;
      }

      // Validate each variation detail item
      for (let k = 0; k < detail.variation_detail_items.length; k++) {
        const detailItem = detail.variation_detail_items[k];

        if (!detailItem.variation_detail_name || detailItem.variation_detail_name.trim() === "") {
          alert(`Detail item ${k + 1} in detail ${j + 1} of variation item ${i + 1} is missing a name.`);
          return;
        }

        if (!isValidPrice(detailItem.variation_detail_price)) {
          alert(`Detail item ${k + 1} in detail ${j + 1} of variation item ${i + 1} has an invalid price. Please enter a positive number greater than zero.`);
          return;
        }
      }
    }
  }

  // If all validations pass, proceed with save
  onSave(customization);
};

const isValidPrice = (price) => {
  // Convert price to a number and check if it's positive and not zero
  const parsedPrice = parseFloat(price);
  return !isNaN(parsedPrice);
};

  const handleCancel = () => {
    onCancel();
  };

  const toggleSection = (index) => {
    setExpandedSections((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const toggleDetailSection = (itemIndex, detailIndex) => {
    setExpandedDetails((prev) => ({
      ...prev,
      [`${itemIndex}-${detailIndex}`]: !prev[`${itemIndex}-${detailIndex}`],
    }));
  };

  
  console.log(customization);
  return (
    <div className="flex flex-col items-center w-full bg-gray-50 p-6 rounded-lg shadow-lg max-h-screen overflow-y-auto">
      <h1 className="text-2xl font-semibold mb-4 text-gray-800">
        {customization.id ? "Edit Customization" : "Create Customization"}
      </h1>

      <div className="flex flex-col w-full p-4 border rounded-lg shadow-inner bg-white">
        <div className="flex flex-row justify-between items-center mb-4">
          <input
            type="text"
            placeholder="Variation Title"
            className="text-sm border-b-2 border-gray-300 focus:border-indigo-500 transition-colors duration-300 p-2 w-full mr-2"
            value={customization.variation_title}
            onChange={(e) => handleInputChange("variation_title", e.target.value)}
          />

          <select
            className="text-sm border-b-2 border-gray-300 focus:border-indigo-500 transition-colors duration-300 p-2 w-full ml-2"
            value={customization.key}
            onChange={(e) => handleInputChange("key", e.target.value)}
          >
            <option value="">Select a key</option>
            {user.role ==="admin" && manufacturer.productionSheetKeys.map((key) => (
              <option key={key} value={key}>
                {key}
              </option>
            ))}
          </select>

          <select
            className="text-sm border-b-2 border-gray-300 focus:border-indigo-500 transition-colors duration-300 p-2 w-full ml-2"
            value={customization.isRule}
            onChange={(e) => {
              if (e.target.value === "true") {
                handleInputChange("isRule", true);
              }else{
                handleInputChange("isRule", false);
              }
            }}
            >
            <option value={true}>Rule</option>
            <option value={false}>Not a Rule</option>
            </select>
        </div>

        <button
          onClick={addNewVariationItem}
          className="bg-indigo-600 text-white text-xs font-semibold py-2 px-4 rounded-md shadow hover:bg-indigo-700 transition-colors duration-300 mb-4"
        >
          Add New Item
        </button>

        {customization.variationItems.map((item, itemIndex) => (
          <div
            key={item.id}
            className="p-4 mb-4 border rounded-lg bg-gray-50 shadow-inner"
          >
            <div className="flex justify-between items-center">
              <div className="flex flex-col w-full">
                <div className="flex justify-between items-center">
                   <p className="mr-2 text-xs font-bold"> {itemIndex+1}.</p>
                  <input
                    type="text"
                    className="text-md font-semibold border-b-2 border-gray-300 focus:border-indigo-500 transition-colors duration-300 p-2 w-full mr-2"
                    value={item.variation_name}
                    onChange={(e) =>
                      handleItemChange(itemIndex, "variation_name", e.target.value)
                    }
                  />
                  <input
                    type="text"
                    className="text-sm text-gray-600 border-b-2 border-gray-300 focus:border-indigo-500 transition-colors duration-300 p-2 w-28 ml-2"
                    value={item.variation_price}
                    onChange={(e) =>
                      handleItemChange(itemIndex, "variation_price", e.target.value)
                    }
                  />
                  <button
                    onClick={() => handleDeleteVariationItem(itemIndex)}
                    className="bg-red-600 text-white text-xs font-semibold py-1 px-3 rounded-md shadow ml-4 hover:bg-red-700 transition-colors duration-300"
                  >
                    Delete
                  </button>
                  <button
                    onClick={() => toggleSection(itemIndex)}
                    className="bg-blue-500 text-white text-xs font-semibold py-1 px-3 rounded-md shadow ml-4 hover:bg-blue-600 transition-colors duration-300"
                  >
                    {expandedSections[itemIndex] ? "Hide" : "Show"}
                  </button>
                </div>
{                

  user.role === "admin" &&
  <div className="flex items-center mt-2">
                  <p className="text-sm font-semibold text-gray-600 mr-2">Price List</p>  
                  <select
                    className="text-sm border-b-2 border-gray-300 focus:border-indigo-500 transition-colors duration-300 p-2 w-28"
                    value={item?.priceListRef?.id}
     
                    onChange={(e) =>
                      {
                        //find the price list
                        
                        let ref = null;
                        priceList.forEach((list) => {
                          if(list.id === e.target.value){
                            ref = list.ref;
                          }
                        })

                        if(ref){
                          handleItemChange(itemIndex, "priceListRef", ref)
                        }else
                        {
                          handleItemChange(itemIndex, "priceListRef", null)
                        }
                      }

                    }
                  >
                    <option value={null}>Select a price list</option>
                    {
                      priceList.map((list) => (
                        <option key={list.id} value={list.id}>{list.price_list_name}</option>
                      ))
                    }
                  </select>
                </div>}
                {expandedSections[itemIndex] && (
                  <>
                    <button
                      onClick={() => addNewVariationDetail(itemIndex)}
                      className="bg-green-600 text-white text-xs font-semibold py-1 px-3 rounded-md shadow mt-4 hover:bg-green-700 transition-colors duration-300"
                    >
                      Add New Detail
                    </button>

                    {item.variation_details.map((detail, detailIndex) => (
                      <div
                        key={detail.id}
                        className="p-3 mt-4 border rounded-lg bg-white shadow-inner"
                      >
                        
                        <div className="flex justify-between items-center">
                        <p className="mr-2 text-xs font-bold"> {detailIndex+1}.</p>
                          <input
                            type="text"
                            className="text-xs font-medium border-b-2 border-gray-300 focus:border-indigo-500 transition-colors duration-300 p-2 w-full"
                            value={detail.variation_detail_title}
                            onChange={(e) =>
                              handleDetailChange(
                                itemIndex,
                                detailIndex,
                                "variation_detail_title",
                                e.target.value
                              )
                            }
                          />
                          <button
                            onClick={() =>
                              handleDeleteVariationDetail(itemIndex, detailIndex)
                            }
                            className="bg-red-600 text-white text-xs font-semibold py-1 px-3 rounded-md shadow ml-4 hover:bg-red-700 transition-colors duration-300"
                          >
                            Delete
                          </button>
                          <button
                            onClick={() =>
                              toggleDetailSection(itemIndex, detailIndex)
                            }
                            className="bg-blue-500 text-white text-xs font-semibold py-1 px-3 rounded-md shadow ml-4 hover:bg-blue-600 transition-colors duration-300"
                          >
                            {expandedDetails[`${itemIndex}-${detailIndex}`]
                              ? "Hide"
                              : "Show"}
                          </button>
                        </div>

                        {expandedDetails[`${itemIndex}-${detailIndex}`] && (
                          <>
                            <button
                              onClick={() =>
                                addNewVariationDetailItem(itemIndex, detailIndex)
                              }
                              className="bg-purple-600 text-white text-xs font-semibold py-1 px-3 rounded-md shadow mt-2 hover:bg-purple-700 transition-colors duration-300"
                            >
                              Add New Option
                            </button>

                            <ul className="list-disc pl-4 mt-2">
                              {detail.variation_detail_items.map(
                                (detailItem, detailItemIndex) => (
                                  <div            key={detailItem.id}>

<li
                         
                                    className="mt-2 flex items-center"
                                  >
                                  <p className="mr-2 text-xs font-bold"> {detailItemIndex+1}.</p>
                                    <input
                                      type="text"
                                      className="text-sm border-b-2 border-gray-300 focus:border-indigo-500 transition-colors duration-300 p-2 w-full mr-2"
                                      value={detailItem.variation_detail_name}
                                      onChange={(e) =>
                                        handleDetailItemChange(
                                          itemIndex,
                                          detailIndex,
                                          detailItemIndex,
                                          "variation_detail_name",
                                          e.target.value
                                        )
                                      }
                                    />{" "}
                                    <span className="text-gray-500 mx-2">-</span>
                                    <input
                                      type="text"
                                      className="text-sm border-b-2 border-gray-300 focus:border-indigo-500 transition-colors duration-300 p-2 w-20"
                                      value={detailItem.variation_detail_price}
                                      onChange={(e) =>
                                        handleDetailItemChange(
                                          itemIndex,
                                          detailIndex,
                                          detailItemIndex,
                                          "variation_detail_price",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <span className="text-gray-500 ml-2">$</span>
                                    <button
                                      onClick={() =>
                                        handleDeleteVariationDetailItem(
                                          itemIndex,
                                          detailIndex,
                                          detailItemIndex
                                        )
                                      }
                                      className="bg-red-600 text-white text-xs font-semibold py-1 px-2 rounded-md shadow ml-4 hover:bg-red-700 transition-colors duration-300"
                                    >
                                      Delete
                                    </button>
                                  </li>
                 {
                 user.role === "admin" &&                 <div className="flex items-center mt-2">
                  <p className="text-sm font-semibold text-gray-600 mr-2">Material</p>  
                  <select
                    className="text-sm border-b-2 border-gray-300 focus:border-indigo-500 transition-colors duration-300 p-2 w-28"
                    value={
                      detailItem.materialID
                    }
     
                    onChange={(e) =>
                      {
                        
                        handleDetailItemChange(
                          itemIndex,
                          detailIndex,
                          detailItemIndex,
                          "materialID",
                          e.target.value
                        )
                          
                      }

                    }
                  >
                    <option value="">Select a material</option>
                    {
                      materials.map((material) => (
                        <option key={material.id} value={material.id}>{material.material_name}</option>
                      ))
                    }
                  </select>
                                  </div>}
                                  </div>
           
                                )
                              )}
                            </ul>
                          </>
                        )}
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

<div className="flex w-full  justify-center">
      <button
        onClick={handleSave}
        className="bg-green-800 mr-1 text-white text-xs font-semibold py-2 px-4 rounded-md shadow mt-6 hover:bg-green-900 transition-colors duration-300"
      >
        Save Customization
      </button>
      <button
        onClick={handleCancel}
        className="bg-green-800 ml-1 text-white text-xs font-semibold py-2 px-4 rounded-md shadow mt-6 hover:bg-green-900 transition-colors duration-300"
      >
        Cancel
      </button>
      </div>
    </div>
  );
}