import React, { useState } from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";

export default function LabelSettings({ labelSettings, setLabelSettings,labelOptions }) {
    const [newLine, setNewLine] = useState([]);

    console.log(labelSettings)

    const addNewLine = () => {
        setLabelSettings([...labelSettings, newLine]);
        setNewLine([]);
    };

    const updateLine = (lineIndex, updatedLine) => {
        const updatedSettings = [...labelSettings];
        updatedSettings[lineIndex] = updatedLine;
        setLabelSettings(updatedSettings);
    };

    const deleteLine = (lineIndex) => {
        setLabelSettings(labelSettings.filter((_, index) => index !== lineIndex));
    };

    const updateObjectInLine = (lineIndex, objectIndex, updatedObject) => {
        const updatedLine = [...labelSettings[lineIndex]];
        updatedLine[objectIndex] = updatedObject;
        updateLine(lineIndex, updatedLine);
    };

    const deleteObjectFromLine = (lineIndex, objectIndex) => {
        const updatedLine = [...labelSettings[lineIndex]].filter(
            (_, index) => index !== objectIndex
        );
        updateLine(lineIndex, updatedLine);
    };

    const addObjectToLine = (lineIndex) => {
        const updatedLine = [
            ...labelSettings[lineIndex],
            { keyName: "", prefix: "", size: "medium" },
        ];
        updateLine(lineIndex, updatedLine);
    };

    return (
        <div>
            {labelSettings?.map((line, lineIndex) => (
                <div key={lineIndex} className="mb-6">
                    <div className="flex justify-between items-center mb-2">
                        <h2 className="text-lg font-bold">Line {lineIndex + 1}</h2>
                        <button
                            onClick={() => deleteLine(lineIndex)}
                            className="text-red-600 hover:text-red-900"
                        >
                            <XMarkIcon className="h-5 w-5" />
                        </button>
                    </div>
                    <div className="space-y-4">
                        {line.map((object, objectIndex) => (
                            <div key={objectIndex} className="flex space-x-4 items-center">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Key Name
                                    </label>
                                    <select
                                        value={object.keyName}
                                        onChange={(e) =>
                                            updateObjectInLine(lineIndex, objectIndex, {
                                                ...object,
                                                keyName: e.target.value,
                                            })
                                        }
                                        className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    >
                                        <option value="">Select a key</option>
                                        {labelOptions.map((option, idx) => (
                                            <option key={idx} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Prefix
                                    </label>
                                    <input
                                        type="text"
                                        value={object.prefix}
                                        onChange={(e) =>
                                            updateObjectInLine(lineIndex, objectIndex, {
                                                ...object,
                                                prefix: e.target.value,
                                            })
                                        }
                                        className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Size
                                    </label>
                                    <select
                                        value={object.size}
                                        onChange={(e) =>
                                            updateObjectInLine(lineIndex, objectIndex, {
                                                ...object,
                                                size: e.target.value,
                                            })
                                        }
                                        className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    >
                                        <option value="7">7px</option>
                                        <option value="8">8px</option>
                                        <option value="9">9px</option>
                                        <option value="10">10px</option>
                                        <option value="11">11px</option>
                                        <option value="12">12px</option>
                                        <option value="13">13px</option>
                                        <option value="14">14px</option>
                                    </select>
                                </div>
                                <button
                                    onClick={() => deleteObjectFromLine(lineIndex, objectIndex)}
                                    className="text-red-600 hover:text-red-900"
                                >
                                    <XMarkIcon className="h-5 w-5" />
                                </button>
                            </div>
                        ))}
                        <button
                            onClick={() => addObjectToLine(lineIndex)}
                            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md shadow-sm hover:bg-blue-600"
                        >
                            Add Field
                        </button>
                    </div>
                </div>
            ))}
            <div className="mt-6">
                <button
                    onClick={addNewLine}
                    className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700"
                >
                    Add New Line
                </button>
            </div>
        </div>
    );
}