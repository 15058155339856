import React, { useState, useEffect } from "react";
import { updateManufacturer } from "../../utils/globalFunctions";
import { toast } from "react-toastify";
import { Dialog } from "@headlessui/react";
import "tailwindcss/tailwind.css";

// Importing Heroicons v2 icons
import {
  BellIcon,
  CreditCardIcon,
  CubeIcon,
  FingerPrintIcon,
  UserCircleIcon,
  UsersIcon,
  XMarkIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import Quickbooks from "./quickbooks_login";
import { set } from "lodash";

const Settings = ({ manufacturer, user }) => {
  const [productionSheets, setProductionSheets] = useState([]);
  const [labelSettings, setLabelSettings] = useState({});
  const [productionSheetOptions, setProductionSheetOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedLabelOptions, setSelectedLabelOptions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [materialTypes, setMaterialTypes] = useState([]);
  const [keys, setKeys] = useState([]);
  const [selectedOptionsSettings, setSelectedOptionsSettings] = useState([]);
  const [selectedLabelOptionsSettings, setSelectedLabelOptionsSettings] =
    useState([]);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [isKeyModalOpen, setIsKeyModalOpen] = useState(false);
  const [newKeyName, setNewKeyName] = useState("");
  const [productionSheetTitle, setProductionSheetTitle] = useState("");
  const [newMaterialTypeName, setNewMaterialTypeName] = useState("");
  const [isGroup,setIsGroup] = useState(false);
  const [group,setGroup] = useState([]); 

  // New state variables for editing production sheets
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isLabelEditModalOpen, setIsLabelEditModalOpen] = useState(false);
  const [sheetToEdit, setSheetToEdit] = useState(null);
  const [editTitle, setEditTitle] = useState("");
  const [editSelectedOptions, setEditSelectedOptions] = useState([]);
  const [editLabelSelectedOptions, setEditLabelSelectedOptions] = useState([]);
  const [editSelectedOptionsSettings, setEditSelectedOptionsSettings] =
    useState([]);
  const [
    editLabelSelectedOptionsSettings,
    setEditLabelSelectedOptionsSettings,
  ] = useState([]);
  const [isMaterialTypeModalOpen, setIsMaterialTypeModalOpen] = useState(false);
  const [labelOptions, setLabelOptions] = useState([]);

  useEffect(() => {
    if (manufacturer) {
      const productionSheetKeys = manufacturer.productionSheetKeys || [];
      const categories = manufacturer.categories || [];
      const materialTypes = manufacturer.materialTypes || [];
      setMaterialTypes(materialTypes);
      setCategories(categories);
      setKeys(productionSheetKeys);
      const defaultOptions = [
        "Fabric Name",
        "Width",
        "Height",
        "Room Name",
        "Quantity",
        "Notes",
        "Matching Rule",
        "Cassette Cut",
        "Tube Cut",
        "Bottom Bar Cut",
        "Bottom Tube Cut",
        "Fabric Width Cut",
        "Fabric Height Cut",
        ...productionSheetKeys,
      ];
      const defaultLabelOptions = [
        "Fabric Name",
        "Customer Name",
        "Measurement",
        "Room Name",
        "Quantity",
        ...productionSheetKeys,
      ];
      setProductionSheetOptions(defaultOptions);
      setLabelOptions(defaultLabelOptions);
      setProductionSheets(manufacturer.productionSheets || []);
      setLabelSettings(manufacturer.labelSettings || {});
      setSelectedLabelOptions(manufacturer.labelSettings?.options || []);
      setSelectedLabelOptionsSettings(
        manufacturer.labelSettings?.settings || []
      );


    }
  }, [manufacturer]);

  const handleSaveProductionSheets = async () => {
    try {
      const updatedManufacturer = {
        ...manufacturer,
        productionSheets,
        materialTypes,
        categories,
        productionSheetKeys: keys,
        id: user.manufacturerID,
        labelSettings,
      };
      await updateManufacturer(updatedManufacturer);
      toast.success("Settings saved successfully");
    } catch (error) {
      toast.error("Failed to save settings");
    }
  };

  const addCategory = () => {
    if (newCategoryName.trim() !== "") {
      setCategories([...categories, newCategoryName.trim()]);
      setNewCategoryName("");
      setIsCategoryModalOpen(false);
      toast.success("Category added");
    } else {
      toast.error("Category name cannot be empty");
    }
  };

  const addMaterialType = () => {
    if (newMaterialTypeName.trim() !== "") {
      setMaterialTypes([...materialTypes, newMaterialTypeName.trim()]);
      setNewMaterialTypeName("");
      setIsMaterialTypeModalOpen(false);
      toast.success("Material type name added");
    } else {
      toast.error("Material name cannot be empty");
    }
  };

  const addKey = () => {
    if (newKeyName.trim() !== "") {
      setKeys([...keys, newKeyName.trim()]);
      setNewKeyName("");
      setIsKeyModalOpen(false);
      toast.success("Key added");
    } else {
      toast.error("Key name cannot be empty");
    }
  };

  const createProductionSheet = () => {
    if (productionSheetTitle.trim() === "") {
      toast.error("Please enter a production sheet title");
      return;
    }
    const newSheet = {
      title: productionSheetTitle.trim(),
      options: selectedOptions,
      settings: selectedOptionsSettings,
    };
    setProductionSheets([...productionSheets, newSheet]);
    setSelectedOptions([]);
    setSelectedOptionsSettings([]);
    setProductionSheetTitle("");
    toast.success("Production sheet created");
  };

  useEffect(() => {
   

      const newSheet = {
        options: selectedLabelOptions,
        settings: selectedLabelOptionsSettings,
      };
      if(newSheet.options.length > 0){
        setLabelSettings({ ...newSheet });
      }
    
  }, [selectedLabelOptions,selectedLabelOptionsSettings]);


  const createLabelSettings = () => {
    const newSheet = {
      options: selectedLabelOptions,
      settings: selectedLabelOptionsSettings,
    };
    setLabelSettings({ ...newSheet });
    setSelectedLabelOptions([]);
    setSelectedLabelOptionsSettings([]);
    toast.success("Production sheet created");
  };

  const handleDeleteCategory = (category) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      const updatedCategories = categories.filter((item) => item !== category);
      setCategories(updatedCategories);
      toast.success("Category deleted");
    }
  };

  const handleDeleteMaterialTypeName = (m) => {
    if (
      window.confirm("Are you sure you want to delete this material type name?")
    ) {
      const updatedMaterials = materialTypes.filter((item) => item !== m);
      setMaterialTypes(updatedMaterials);
      toast.success("Material name deleted, please don`t forget to save.");
    }
  };

  const handleDeleteKey = (key) => {
    if (window.confirm("Are you sure you want to delete this key?")) {
      const updatedKeys = keys.filter((item) => item !== key);
      setKeys(updatedKeys);
      toast.success("Key deleted");
    }
  };

  const handleDeleteProductionSheet = (sheetTitle) => {
    if (
      window.confirm("Are you sure you want to delete this production sheet?")
    ) {
      const updatedSheets = productionSheets.filter(
        (item) => item.title !== sheetTitle
      );
      setProductionSheets(updatedSheets);
      toast.success("Production sheet deleted");
    }
  };

  const handleDeleteLabelSettings = () => {
    if (window.confirm("Are you sure you want to clear this label settings?")) {
      setSelectedLabelOptionsSettings([]);
      setSelectedLabelOptions([]);
      setLabelSettings({});
      toast.success("Label settings deleted");
    }
  };

  const openEditModal = (sheet) => {
    setSheetToEdit(sheet);
    setEditTitle(sheet.title);
    setEditSelectedOptions(sheet.options);
    setEditSelectedOptionsSettings(sheet.settings);
    setIsEditModalOpen(true);
  };



  const saveEditedSheet = () => {
    if (editTitle.trim() === "") {
      toast.error("Please enter a production sheet title");
      return;
    }
    const updatedSheet = {
      title: editTitle.trim(),
      options: editSelectedOptions,
      settings: editSelectedOptionsSettings,
    };
    setProductionSheets(
      productionSheets.map((sheet) =>
        sheet.title === sheetToEdit.title ? updatedSheet : sheet
      )
    );
    setIsEditModalOpen(false);
    toast.success("Production sheet updated");
  };

  const saveEditedLabelSettings = () => {
    const updatedSheet = {
      options: editLabelSelectedOptions,
      settings: editLabelSelectedOptionsSettings,
    };
    setLabelSettings({ ...updatedSheet });
    setIsEditModalOpen(false);
    toast.success("Production sheet updated");
  };

  return (
    <div className="max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:px-8">
      {/* <Quickbooks manufacturer={manufacturer} /> */}
      <h1 className="text-3xl font-bold leading-tight text-gray-900">
        Settings
      </h1>

      {/* Categories Section */}
      <div className="mt-10">
        <h2 className="text-xl font-semibold text-gray-800">Categories</h2>
        <div className="mt-4 bg-white shadow sm:rounded-md">
          <ul className="divide-y divide-gray-200">
            {categories.map((category, index) => (
              <li
                key={index}
                className="flex items-center justify-between px-4 py-4 sm:px-6"
              >
                <div className="flex items-center">
                  <span className="text-sm font-medium text-gray-900">
                    {category}
                  </span>
                </div>
                <div>
                  <button
                    onClick={() => handleDeleteCategory(category)}
                    className="text-red-600 hover:text-red-900"
                  >
                    Delete
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-4 flex">
          <button
            onClick={() => setIsCategoryModalOpen(true)}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
          >
            Add Category
          </button>
        </div>
      </div>
      {/* Material Types Section */}
      <div className="mt-10">
        <h2 className="text-xl font-semibold text-gray-800">
          Material Categories
        </h2>
        <div className="mt-4 bg-white shadow sm:rounded-md">
          <ul className="divide-y divide-gray-200">
            {materialTypes.map((m, index) => (
              <li
                key={index}
                className="flex items-center justify-between px-4 py-4 sm:px-6"
              >
                <div className="flex items-center">
                  <span className="text-sm font-medium text-gray-900">{m}</span>
                </div>
                <div>
                  <button
                    onClick={() => handleDeleteMaterialTypeName(m)}
                    className="text-red-600 hover:text-red-900"
                  >
                    Delete
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-4 flex">
          <button
            onClick={() => setIsMaterialTypeModalOpen(true)}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
          >
            Add Material Category
          </button>
        </div>
      </div>

      {/* Keys Section */}
      <div className="mt-10">
        <h2 className="text-xl font-semibold text-gray-800">Keys</h2>
        <div className="mt-4 bg-white shadow sm:rounded-md">
          <ul className="divide-y divide-gray-200">
            {keys.map((key, index) => (
              <li
                key={index}
                className="flex items-center justify-between px-4 py-4 sm:px-6"
              >
                <div className="flex items-center">
                  <span className="text-sm font-medium text-gray-900">
                    {key}
                  </span>
                </div>
                <div>
                  <button
                    onClick={() => handleDeleteKey(key)}
                    className="text-red-600 hover:text-red-900"
                  >
                    Delete
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-4 flex">
          <button
            onClick={() => setIsKeyModalOpen(true)}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
          >
            Add Key
          </button>
        </div>
      </div>


      {/* Save Button */}
      <div className="mt-10">
        <button
          onClick={handleSaveProductionSheets}
          className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700"
        >
          Save Settings
        </button>
      </div>

      {/* Modals */}
      {/* Add Category Modal */}
      <Dialog
        open={isCategoryModalOpen}
        onClose={() => setIsCategoryModalOpen(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto max-w-sm rounded bg-white p-6">
            <Dialog.Title className="text-lg font-medium text-gray-900">
              Add Category
            </Dialog.Title>
            <div className="mt-4">
              <label
                htmlFor="newCategoryName"
                className="block text-sm font-medium text-gray-700"
              >
                Category Name
              </label>
              <input
                type="text"
                id="newCategoryName"
                value={newCategoryName}
                onChange={(e) => setNewCategoryName(e.target.value)}
                className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="Enter category name"
              />
            </div>
            <div className="mt-6 flex justify-end space-x-4">
              <button
                onClick={() => setIsCategoryModalOpen(false)}
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={addCategory}
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700"
              >
                Add
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      {/* Add Material Modal */}
      <Dialog
        open={isMaterialTypeModalOpen}
        onClose={() => setIsMaterialTypeModalOpen(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto max-w-sm rounded bg-white p-6">
            <Dialog.Title className="text-lg font-medium text-gray-900">
              Add Material
            </Dialog.Title>
            <div className="mt-4">
              <label
                htmlFor="newCategoryName"
                className="block text-sm font-medium text-gray-700"
              >
                Material Name
              </label>
              <input
                type="text"
                id="newCategoryName"
                value={newMaterialTypeName}
                onChange={(e) => setNewMaterialTypeName(e.target.value)}
                className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="Enter category name"
              />
            </div>
            <div className="mt-6 flex justify-end space-x-4">
              <button
                onClick={() => setIsMaterialTypeModalOpen(false)}
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={addMaterialType}
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700"
              >
                Add
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      {/* Add Key Modal */}
      <Dialog
        open={isKeyModalOpen}
        onClose={() => setIsKeyModalOpen(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto max-w-sm rounded bg-white p-6">
            <Dialog.Title className="text-lg font-medium text-gray-900">
              Add Key
            </Dialog.Title>
            <div className="mt-4">
              <label
                htmlFor="newKeyName"
                className="block text-sm font-medium text-gray-700"
              >
                Key Name
              </label>
              <input
                type="text"
                id="newKeyName"
                value={newKeyName}
                onChange={(e) => setNewKeyName(e.target.value)}
                className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="Enter key name"
              />
            </div>
            <div className="mt-6 flex justify-end space-x-4">
              <button
                onClick={() => setIsKeyModalOpen(false)}
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={addKey}
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700"
              >
                Add
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>


    </div>
  );
};

export default Settings;
