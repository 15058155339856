import React, { useEffect, useState } from 'react';
import { db } from '../../utils/firebase'; // Adjust path to your Firebase setup file
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';

const EditableProductsTable = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    // Define the fields to display and edit
    const displayedFields = [
        'id',
        'product_title',
        'product_price',
        'manufacturer_name',
        'isPublic',
        'isAvailable',
        'product_category',
        'type',
        'store_id',
    ];

    // Fetch products from Firestore
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const productsCollection = collection(db, 'Products');
                const snapshot = await getDocs(productsCollection);

                if (!snapshot.empty) {
                    const productsData = snapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
                    setProducts(productsData);
                }
            } catch (error) {
                console.error('Error fetching products:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    // Update a field in Firestore
    const handleUpdate = async (id, field, value) => {
        try {
            const productRef = doc(db, 'products', id);
            await updateDoc(productRef, { [field]: value });
            setProducts((prevProducts) =>
                prevProducts.map((product) =>
                    product.id === id ? { ...product, [field]: value } : product
                )
            );
        } catch (error) {
            console.error('Error updating product:', error);
        }
    };

    if (loading) {
        return <div className="flex justify-center items-center h-screen">Loading...</div>;
    }

    return (
        <div className="p-6 bg-gray-50 min-h-screen">
            <div className="max-w-7xl mx-auto bg-white rounded-lg shadow-lg p-6">
                <h1 className="text-2xl font-bold mb-6 text-gray-800">Products Dashboard</h1>
                <div className="overflow-auto">
                    <table className="w-full border-collapse table-auto text-left">
                        <thead>
                            <tr className="bg-gray-100 text-gray-700 text-sm uppercase">
                                {displayedFields.map((field) => (
                                    <th key={field} className="border-b px-4 py-3">
                                        {field.replace(/_/g, ' ')}
                                    </th>
                                ))}
                                <th className="border-b px-4 py-3">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products.map((product) => (
                                <tr key={product.id} className="hover:bg-gray-50">
                                    {displayedFields.map((field) => (
                                        <td key={field} className="border-b px-4 py-2">
                                            {typeof product[field] === 'boolean' ? (
                                                <select
                                                    className="p-2 border rounded"
                                                    value={product[field]}
                                                    onChange={(e) =>
                                                        handleUpdate(
                                                            product.id,
                                                            field,
                                                            e.target.value === 'true'
                                                        )
                                                    }
                                                >
                                                    <option value={true}>True</option>
                                                    <option value={false}>False</option>
                                                </select>
                                            ) : typeof product[field] === 'number' ? (
                                                <input
                                                    type="number"
                                                    className="w-full p-2 border rounded"
                                                    value={product[field] || ''}
                                                    onChange={(e) =>
                                                        handleUpdate(
                                                            product.id,
                                                            field,
                                                            Number(e.target.value)
                                                        )
                                                    }
                                                />
                                            ) : (
                                                <input
                                                    type="text"
                                                    className="w-full p-2 border rounded"
                                                    value={product[field] || ''}
                                                    onChange={(e) =>
                                                        handleUpdate(
                                                            product.id,
                                                            field,
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            )}
                                        </td>
                                    ))}
                                    <td className="border-b px-4 py-2">
                                        <button
                                            className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
                                            onClick={() =>
                                                alert(
                                                    `Viewing product: ${product.product_title || 'Unnamed'}`
                                                )
                                            }
                                        >
                                            View
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default EditableProductsTable;