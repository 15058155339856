import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  orderBy,
  endBefore,
  limitToLast,
  getDoc,
  doc,
} from "firebase/firestore";
import { startAfter, limit } from "firebase/firestore";
import ModalComponent from "../../components/ModalComponent";
import {
  globalAlert,
  statusColor,
  updateStatusOrder,
  getAllGlobalVariations,
  getStoreData,
  getCustomers,
  deleteCreatedOrder,
  globalSuccess
} from "../../utils/globalFunctions";
import { db } from "../../utils/firebase";
import { onSnapshot } from "firebase/firestore";
import { Button } from "flowbite-react";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import algoliasearch from "algoliasearch/lite";
import TableComponent from "../../components/TableComponent";
import { PDFDocument, StandardFonts } from "pdf-lib";
import { getOrder, getGlobalRules } from "../../utils/globalFunctions";
import { handlePrintOrderPDFsTemp } from "../../utils/handlePrintOrderPDFsTemp";
import autoTable from "jspdf-autotable";
import { jsPDF } from "jspdf";
import { saveManufacturerCutRules } from "../../utils/globalFunctions";
import OrderEntries from "../store/OrderEntries";
import RuleEntryComponent from "../../components/RuleEntryComponent";

const searchClient = algoliasearch(
  "G8KAJNNACO",
  "19a59bc05c5ad87988fb055629980009"
);

const convertToFraction = (decimal) => {
  const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));
  const wholeNumber = Math.floor(decimal);
  const fraction = decimal - wholeNumber;
  const numerator = Math.round(fraction * 1000);
  const denominator = 1000;
  const divisor = gcd(numerator, denominator);
  return `${wholeNumber} ${numerator / divisor}/${denominator / divisor}`;
};

const OrderList = ({ user, manufacturer }) => {
  const [orders, setOrders] = useState([]);
  const [searchOrder, setSearchOrders] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [globalRules, setGloalRules] = useState([]);
  const [status, setStatus] = useState("Processing");
  const [searchTerm, setSearchTerm] = useState("");
  const [chatModal, setChatModal] = useState(false);
  const [chatOrderId, setChatOrderId] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [keyMap, setKeyMap] = useState({});
  const [isRuleMap, setIsRuleMap] = useState({});
  const [noRule, setNoRule] = useState(false);
  const [noRuleOrderID, setNoRuleOrderID] = useState("");
  const [noRuleOrders, setNoRuleOrders] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [lastDoc, setLastDoc] = useState(null); // State to keep track of the last document
  const [firstDoc, setFirstDoc] = useState(null); // State to keep track of the first document
  const [nextOrBack, setNextOrBack] = useState("none");
  const [isLastOrder, setIsLastOrder] = useState(false);
  const [chosenPDF, setChosenPDF] = useState(14);
  const [emptySquares, setEmptySquares] = useState(0);
  const [orderID, setOrderID] = useState("");
  const [isSearchMode, setIsSearchMode] = useState(false); // Track if we're in search mode
  const [searchPageCount, setSearchPageCount] = useState(0); // Track Algolia page number
  const [open, setOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);
  const [tempOrderItems, setTempOrderItems] = useState([]);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");
  const [storeCustomers, setStoreCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");

  const navigate = useNavigate();

  const [sortOrder, setSortOrder] = useState("asc"); // State to track sort order

  useEffect(() => {
    getAllGlobalVariations(user.manufacturerID).then((data_) => {
      console.log(data_);
      let map = {};
      let map1 = {};
      let history = [];
      data_.forEach((item) => {
        console.log("item", item);
        history.push(item);
        // check if the key is already in the map
        if (map[item.variation_title]) {
          // find the object in the history array
          let obj = history.find(
            (o) => o.variation_title === item.variation_title
          );
          // if the object is found, check the time createdAt and update the map with the latest object

          if (obj.isDeleted && !item.isDeleted) {
            console.log("item", item);

            map[item.variation_title] = item?.key ? item.key : "Customizations";
            map1[item.variation_title] = item?.isRule ? item.isRule : false;

            // remove the old object from the history array
            history = history.filter(
              (o) => o.variation_title !== item.variation_title
            );

            // add the new object to the history array
            history.push(item);
          }
        } else {
          if (!item?.key) {
            console.log("item", item);
          }

          map[item.variation_title] = item?.key
            ? item.key
            : item.variation_title;
          map1[item.variation_title] = item?.isRule ? item.isRule : false;
        }
      });
      setIsRuleMap(map1);
    });
  }, [user.manufacturerID]);

  useEffect(() => {
    if (manufacturer) {
      // Fetch stores
      manufacturer.subscribers.forEach((store) => {
        getStoreData({ store_id: store }).then((data) => {
          setStores((prev) => [...prev, data]);
        });
      });
    }
  }, [manufacturer]);

  useEffect(() => {
    if (selectedStore !== "") {
      console.log("selectedStore", selectedStore);
      getCustomers(selectedStore).then((customers) => {
        setStoreCustomers(customers);
      });
    }
  }, [selectedStore]);

  const handleSortOrderToggle = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    // Reset pagination
    setPageCount(0);
    setLastDoc(null);
    setFirstDoc(null);
    setNextOrBack("none");
  };

  const columns = [
    { Header: "Order ID", accessor: "orderID" },
    { Header: "Store Name", accessor: "store_name" },
    { Header: "Customer Name", accessor: "customer_name" },
    { Header: "Status", accessor: "customStatus" },
    {
      Header: "Total",
      Cell: ({ row }) => {
        return `$${row.total}`;
      },
    },
    {
      Header: "Created At",
      accessor: "created_at",
      HeaderIcon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-4 h-4 ml-2 cursor-pointer"
          onClick={handleSortOrderToggle}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d={`M${
              sortOrder === "asc"
                ? "7 14l5-5m0 0l5 5m-5-5v12"
                : "17 10l-5 5m0 0l-5-5m5 5V3"
            }`}
          />
        </svg>
      ),
    },
    { Header: "Actions", accessor: "customActions" },
  ];

  const listenToChangesWithCondition = () => {
    const baseQuery = [
      collection(db, "Orders"),
      where("is_deleted", "==", false),
      where("status", "==", status),
      where("manufacturers", "array-contains", user.manufacturerID),
      orderBy("created_at", sortOrder),
    ];

    // Add conditional filters based on inputs
    if (selectedStore) {
      baseQuery.push(where("store_id", "==", selectedStore));
    }

    if (selectedCustomer) {
      baseQuery.push(where("customer_id", "==", selectedCustomer));
    }

    // Add pagination conditions
    if (nextOrBack === "next") {
      baseQuery.push(startAfter(lastDoc), limit(8));
    } else if (nextOrBack === "back") {
      baseQuery.push(endBefore(firstDoc), limitToLast(8));
    } else {
      baseQuery.push(limit(8));
    }

    // Build the query
    const q = query(...baseQuery);

    const unsubscribeOrders = onSnapshot(q, (querySnapshot) => {
      const documents = querySnapshot.docs;

      if (documents.length > 0) {
        setLastDoc(documents[documents.length - 1]);
        setFirstDoc(documents[0]);

        if (documents.length < 8) {
          setIsLastOrder(true);
        } else {
          setIsLastOrder(false);
        }

        const ordersData = documents.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            orderID: doc.id.slice(-4).toUpperCase(),
            ...data,
            created_at: data.created_at.toDate().toLocaleDateString(),
            customStatus: (
              <p
                className={`${
                  statusColor[data.status]
                } p-2 rounded text-center font-bold text-xs text-white`}
              >
                {data.status}
              </p>
            ),
            customActions: (
              <div className="flex justify-center">
            
                  <Button
                    size={"xs"}
                    onClick={async() =>
                      // confirm from user before deleting
                      window.confirm("Are you sure you want to delete this order?") &&
                      deleteCreatedOrder(doc.id).then((res) => {
                        if (res) {
                          globalSuccess(
                            "Order deleted successfully"
                          );
                        }else{
                          globalAlert(
                            "Failed to delete order"
                          );
                        }
                      })
                    }

                    className="bg-gray-400 mr-1 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                  >
                    Delete
                  </Button>

                {data.status === "Processing" && (
                  <Button
                    size={"xs"}
                    onClick={() =>
                      handleUpdateStatusOrder(doc.id, "In Production")
                    }
                    className="bg-gray-400 mr-1 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                  >
                    Start Production
                  </Button>
                )}
                {data.status === "In Production" && (
                  <Button
                    size={"xs"}
                    onClick={() =>
                      handleUpdateStatusOrder(doc.id, "Production Completed")
                    }
                    className="bg-gray-400 mr-1 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                  >
                    Complete Production
                  </Button>
                )}
                <Button
                  size={"xs"}
                  onClick={() => setOrderID(doc.id)}
                  className="bg-gray-400 ml-1 hover:cursor-pointer hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                >
                  {/* View Details Button */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 hover:cursor-pointer"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                </Button>
                <Button
                  size={"xs"}
                  onClick={() => {
                    setOpen(true);
                    setSelectedOrder(data);
                  }}
                  className="bg-gray-400 ml-1 hover:cursor-pointer hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                >
                  {/* Print Button */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z"
                    />
                  </svg>
                </Button>
              </div>
            ),
          };
        });

        setOrders(ordersData);
      } else {
        setOrders([]); // Update orders to empty array when no documents are returned
        setIsLastOrder(true);
      }
      setIsLoading(false);
    });

    return () => unsubscribeOrders();
  };

  useEffect(() => {
    if (!isSearchMode && searchTerm.trim().length === 0) {
      const unsubscribe = listenToChangesWithCondition();
      return () => unsubscribe();
    }
  }, [
    status,
    pageCount,
    sortOrder,
    isSearchMode,
    selectedStore,
    selectedCustomer,
  ]);

  const handleUpdateStatusOrder = (id, s) => {
    updateStatusOrder(id, s).then((res) => {
      if (res) {
        // Optionally handle success
      }
    });
  };

  if (orderID.length > 0)
    return (
      <OrderEntries user={user} order_id={orderID} setOrderID={setOrderID} />
    );

  if (noRule) {
    return (
      <RuleEntryComponent
        noRule={noRule}
        setNoRule={setNoRule}
        noRuleOrders={noRuleOrders}
        manufacturer={manufacturer}
        setNoRuleOrders={setNoRuleOrders}
        isRuleMap={isRuleMap}
        user={user}
        saveManufacturerCutRules={saveManufacturerCutRules}
      />
    );
  }

  return (
    <>
      <div className=" flex flex-row justify-center">
        <ModalComponent
          open={open}
          setOpen={setOpen}
          title={loadingModal ? "Please wait..." : "Select Production Sheet"}
          children={
            <div className="flex flex-col">
              {loadingModal ? (
                <div className="flex justify-center items-center">
                  <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
                </div>
              ) : (
                <>
                  {manufacturer?.productionSheets?.map((sheet) => (
                    <Button
                      size={"xs"}
                      onClick={() => {
                        setLoadingModal(true);
                        handlePrintOrderPDFsTemp(
                          selectedOrder,
                          sheet,
                          user,
                          manufacturer,
                          setLoadingModal,
                          setOpen,
                          setNoRuleOrders,
                          setNoRule
                        );
                      }}
                      className="bg-gray-400 mr-1 mt-2 hover:bg-gray-600 text-sm text-white font-bold py-2 px-4 rounded"
                    >
                      {sheet.title}
                    </Button>
                  ))}
                  <Button
                    size={"xs"}
                    onClick={() => {
                      handlePrintOrderPDFsTemp(
                        selectedOrder,
                        "label1",
                        user,
                        manufacturer,
                        setLoadingModal,
                        setOpen,
                        setNoRuleOrders,
                        setNoRule
                      );
                    }}
                    className="bg-gray-400 mr-1 mt-2 hover:bg-gray-600 text-sm text-white font-bold py-2 px-4 rounded"
                  >
                    Generate Labels (3 * 10)
                  </Button>
                  <Button
                    size={"xs"}
                    onClick={() => {
                      handlePrintOrderPDFsTemp(
                        selectedOrder,
                        "label2",
                        user,
                        manufacturer,
                        setLoadingModal,
                        setOpen,
                        setNoRuleOrders,
                        setNoRule
                      );
                    }}
                    className="bg-gray-400 mr-1 mt-2 hover:bg-gray-600 text-sm text-white font-bold py-2 px-4 rounded"
                  >
                    Generate Labels (2 * 7)
                  </Button>
                </>
              )}
            </div>
          }
        />
        <div className="flex flex-col sm:w-1/2">
          <h1 className="text-2xl font-bold">{status} Orders</h1>
          <div className="flex">
            <select
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
                setNextOrBack("none");
                setPageCount(0);
              }}
              className="bg-white text-xs p-2 w-1/2 mt-1 rounded border"
            >
              <option value="Processing">Processing</option>
              <option value="In Production">In Production</option>
              <option value="Production Completed">Production Completed</option>
            </select>

            <div className="flex">
              <select
                value={selectedStore}
                onChange={(e) => {
                  setSelectedStore(e.target.value);
                }}
                className="bg-white text-xs p-2 ml-2 mt-1 rounded border"
              >
                <option value="">Filter by store</option>
                {stores.map((store) => {
                  return (
                    <option value={store.store_id}>{store.storeName}</option>
                  );
                })}
              </select>

              <select
                value={selectedCustomer}
                onChange={(e) => {
                  setSelectedCustomer(e.target.value);
                }}
                className="bg-white text-xs p-2 ml-2 mt-1 rounded border"
              >
                <option value="">Filter by store customer</option>
                {storeCustomers.map((customer) => {
                  return (
                    <option value={customer.id}>{customer.customerName}</option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="sm:pl-8 sm:pr-8 flex flex-wrap justify-center">
        <div className="w-full overflow-x-auto">
          <div className="flex justify-between items-center mb-2">
            <p className="text-gray-700 dark:text-gray-400 text-sm ml-1">
              Page <a className="font-bold">{pageCount + 1}</a>
            </p>

            <div>
              <button
                disabled={
                  (isSearchMode && searchPageCount === 0) ||
                  (!isSearchMode && pageCount === 0) ||
                  isLoading
                }
                onClick={() => {
                  if (isSearchMode && searchPageCount > 0) {
                    // In search mode, decrease the search page count
                    //storeFilter has been added instead of handleSearch
                    // handleSearch(searchPageCount - 1);
                  } else if (!isSearchMode && pageCount > 0) {
                    // In Firestore mode, go back to the previous page
                    setNextOrBack("back");
                    setPageCount(pageCount - 1);
                  }
                }}
                className="mr-1 hover:text-black cursor-pointer text-gray-800 font-bold py-2 px-4 rounded"
              >
                Previous
              </button>

              <button
                disabled={isLastOrder || isLoading} // Disable when it's the last page or loading
                onClick={() => {
                  if (isSearchMode) {
                    // In search mode, increase the search page count
                    //storeFilter has been added instead of handleSearch
                    // handleSearch(searchPageCount + 1);
                  } else {
                    // In Firestore mode, go to the next page
                    setNextOrBack("next");
                    setPageCount(pageCount + 1);
                  }
                }}
                className="mr-1 hover:text-black cursor-pointer text-gray-800 font-bold py-2 px-4 rounded"
              >
                Next
              </button>
            </div>
          </div>
          <div className="table-container">
            <div className="w-full mx-auto">
              <TableComponent
                columns={columns}
                data={orders}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderList;
